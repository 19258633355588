import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import RoleModal from '../Modal/RoleModal/RoleModal';
import NoticesComp from '../Notice/NoticesComp';
import PrivacyModal from '../Modal/PrivacyModal/PrivacyModal';
import FeedbackModal from '../Modal/FeedbackModal/FeedbackModal';
import RouterProvider from '../../Routes/RouterProvider';
import VersionUpdater from '../VersionUpdater/VersionUpdater';
import { routes } from '../../Routes/Routes';
import { getFeatures } from '../../Store/AppVersion/appVersionSlice';
import { preSelectEic } from '../../Store/ServicePoints/servicePointsSlice';
import { AppMainStyles } from './AppMain.styles';
import { getLastReading } from '../../Store/LastReading/lastReadingSlice';
import { getFirebaseToken } from '../../firebase';
import { validateRegistration } from '../../Store/Notification/notificationSlice';
import { NotificationUrlParams } from '../../Helpers/Enums';
import { doLogin, getParsedToken } from '../../keycloak';
import { getCookie, setCookie, showFooter } from '../../Helpers/HelperMethods';
import {
  preSelectRole,
  getCustomerInfo,
  clearCustomerInfo,
  setCustomerLanguage,
  getCustomerInfoSuccess,
} from '../../Store/CustomerInfo/customerInfoSlice';

function AppMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();

  const isAuthenticated = keycloak.authenticated;

  const { roleModalOpen, customerInfo } = useSelector(state => state.customerInfo);
  const { activeStep } = useSelector(state => state.commonNotify);
  const { privacyModalOpen, feedbackModalOpen, isMobile } = useSelector(state => state.common);

  const showMainFooter = showFooter(location, activeStep, isMobile);

  useEffect(() => {
    dispatch(getFeatures());

    if (!isAuthenticated) {
      dispatch(clearCustomerInfo());
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getLastReading());
    }
  }, [location]);

  useEffect(() => {
    const lang = getCookie('lang');

    if (lang) {
      i18n.changeLanguage(lang).then();
      document.documentElement.setAttribute('lang', lang);
      if (customerInfo.userSelectedLanguage && isAuthenticated) {
        if (lang !== customerInfo.userSelectedLanguage && customerInfo.id) {
          dispatch(setCustomerLanguage({ language: lang }));
        }
      }
    } else {
      if (customerInfo.userSelectedLanguage) {
        setCookie('lang', customerInfo.userSelectedLanguage);
        i18n.changeLanguage(customerInfo.userSelectedLanguage).then();
        document.documentElement.setAttribute('lang', customerInfo.userSelectedLanguage);
      }
    }
  }, [customerInfo]);

  useEffect(() => {
    const customerId = getParsedToken() ? getParsedToken().customer_id : null;
    if (openedFromPushNotification() && !isAuthenticated) {
      doLogin();
    }

    if (isAuthenticated) {
      getPushNotificationRegistration();

      if (openedFromPushNotification()) {
        handleUrlParams();
      }
      if (customerInfo.id !== customerId) {
        dispatch(getCustomerInfo({ customerId: customerId }));
      }
    }
  }, [dispatch, isAuthenticated]);

  const openedFromPushNotification = () => {
    return (
      location.search &&
      location.search.indexOf(NotificationUrlParams.role) !== -1 &&
      location.search.indexOf(NotificationUrlParams.eic) !== -1
    );
  };

  const getPushNotificationRegistration = () => {
    if (window.Notification && window.Notification.permission === 'granted' && getParsedToken().customer_id) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        registrations.forEach(v => {
          if (v.active && v.active.state === 'activated' && v.active.scriptURL.indexOf('firebase-messaging-sw') !== -1) {
            // firebase SW is registered in browser, check if token exists in database
            getFirebaseToken().then(token => {
              dispatch(validateRegistration(token));
            });
          }
        });
      });
    }
  };

  const handleUrlParams = () => {
    const urlParams = new URLSearchParams(location.search);
    const roleId = urlParams.get(NotificationUrlParams.role);
    const eic = urlParams.get(NotificationUrlParams.eic);

    navigate(location.pathname);

    if (roleId) {
      dispatch(preSelectRole({ roleId }));
    }

    if (eic) {
      dispatch(preSelectEic({ eic }));
    }
    if (!!customerInfo) {
      dispatch(getCustomerInfoSuccess(customerInfo));
    }
  };

  return (
    <AppMainStyles hasPadding={showMainFooter}>
      <div id='content-wrap'>
        <Header />
        <NoticesComp />
        <RouterProvider routes={routes} />
      </div>
      <VersionUpdater />
      {showMainFooter && <Footer />}
      {roleModalOpen && <RoleModal />}
      {privacyModalOpen && <PrivacyModal />}
      {feedbackModalOpen && <FeedbackModal />}
    </AppMainStyles>
  );
}

export default AppMain;
