import { createSlice } from '@reduxjs/toolkit';
import { GaAction, GaEvent, GaEvents } from '../../Helpers/GoogleAnalytics';

const dangerNotifySlice = createSlice({
  name: 'dangerNotify',
  initialState: {
    locationData: {
      address: '',
      x: '',
      y: '',
      lat: '',
      lng: '',
    },
  },
  reducers: {
    clearDangerNotify(state) {
      return {
        ...state,
        locationData: { address: '', x: '', y: '', lat: '', lng: '' },
      };
    },
    setLocationData(state, action) {
      const { payload } = action;
      GaEvent(GaEvents.pageNotify, { group: GaAction.DangerousSituation, action: GaAction.MapLocationAdded });
      return {
        ...state,
        locationData: payload,
      };
    },
  },
});

export const getLocationData = state => {
  return state.dangerNotify.locationData;
};

export const { clearDangerNotify, setLocationData } = dangerNotifySlice.actions;

export default dangerNotifySlice.reducer;
