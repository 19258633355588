import React from 'react';
import PropType from 'prop-types';

import { ForwardBtnStyles } from './ForwardBtn.styles';

function ForwardBtn(props) {
  const { label, onClick, icon, disabled, noPadding } = props;

  return (
    <ForwardBtnStyles noPadding={noPadding}>
      <e-button data-testid='forwardBtn' color='gradient-primary' onClick={onClick} disabled={disabled}>
        <span>{label}</span>
        {icon && <e-icon icon={icon} />}
      </e-button>
    </ForwardBtnStyles>
  );
}

ForwardBtn.propTypes = {
  label: PropType.string,
  icon: PropType.string,
  onClick: PropType.func,
  disabled: PropType.bool,
  noPadding: PropType.bool,
};

export default ForwardBtn;
