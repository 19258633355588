import styled from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';
import { NoticeTypes } from '../../Helpers/Enums';

const getMargins = params => {
  if (params.type === NoticeTypes.danger) {
    return '0 -2em 1em -2em';
  } else if (params.type === NoticeTypes.feedback || params.type === NoticeTypes.feedbackError) {
    return '0 -1.5em 0 -1.5em';
  } else if (params.type === NoticeTypes.success || params.type === NoticeTypes.warning) {
    return '0';
  }
  return '0';
};

const getPaddings = params => {
  if (params.type === NoticeTypes.feedback || params.type === NoticeTypes.error) {
    return '1em 1.5em';
  } else if (params.type === NoticeTypes.success) {
    return '1em 2em';
  }
  return '1em 2em';
};

const getJustifyContent = params => {
  if (params.align === 'center') {
    return 'center';
  }
  return 'none';
};

const getColor = (params, colorType) => {
  if (params.type === NoticeTypes.success || params.type === NoticeTypes.feedback) {
    return Colors.green[colorType];
  } else if (params.type === NoticeTypes.warning || params.type === NoticeTypes.danger) {
    return Colors.yellow[colorType];
  } else if (params.type === NoticeTypes.error) {
    return Colors.red[colorType];
  }
  return Colors.background[colorType];
};

export const NoticeStyles = styled.div`
  padding: ${props => getPaddings(props)};
  background-color: ${props => getColor(props, 'bg')};
  display: flex;
  align-items: center;
  margin: ${props => getMargins(props)};
  justify-content: ${props => getJustifyContent(props)};

  .notify__message {
    padding-left: 0.5em;
  }

  .icon {
    color: ${props => getColor(props, 'primary')};
  }

  .close-btn {
    position: absolute;
    right: 0;
    margin-right: 1em;
  }
`;
