import moment from 'moment';
import Resizer from 'react-image-file-resizer';

import { ROUTE_PATHS } from '../Routes/Routes';
import { ServicePointStatusEnum, NotifySteps, ServicePointType, ServicePointEventType } from './Enums';
import { dateTimeFormat, timeFormat } from './Common';

export const hasServicePoint = servicePoint => {
  return !!servicePoint.address;
};

export const closeDropdown = id => {
  const element = document.getElementById(id);
  if (element) {
    const buttons = element.getElementsByTagName('button');
    if (buttons.length > 0) {
      buttons[0].click();
    }
  }
};

export const isActiveServicePoint = (servicePoint1, servicePoint2) => {
  return JSON.stringify(servicePoint1) === JSON.stringify(servicePoint2);
};

export const getCaretDirection = open => {
  return open ? 'caret-left' : 'caret-right';
};

export const getHomeIconType = servicePoint => {
  return servicePoint.type === ServicePointType.APARTMENT ? 'building-lg' : 'home-simple';
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const hasMessages = messages => {
  return messages && messages.length > 0;
};

export const givenDateAfterNow = date => {
  return moment(date).isAfter(moment(moment.now()));
};

export const presentTimeBetweenStartAndEnd = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return false;
  }
  return moment(startTime).isBefore(moment(moment.now())) && moment(endTime).isAfter(moment(moment.now()));
};

export const givenStatusActive = status => {
  return status && presentTimeBetweenStartAndEnd(status.statusValidFrom, status.statusValidUntil);
};

export const givenStatusActiveAndMsgStartOrInfo = status => {
  return (
    givenStatusActive(status) &&
    (status.status === ServicePointStatusEnum.OUTAGE_START || status.status === ServicePointStatusEnum.OUTAGE_INFO)
  );
};

export const isStatusPlanned = status => {
  return (
    status &&
    (status.status === ServicePointStatusEnum.PLANNED_OUTAGE ||
      status.status === ServicePointStatusEnum.PLANNED_OUTAGE_ONGOING ||
      status.status === ServicePointStatusEnum.PLANNED_OUTAGE_DELETED ||
      status.status === ServicePointStatusEnum.PLANNED_OUTAGE_EXCEPTIONALLY_ADDED ||
      status.status === ServicePointStatusEnum.PLANNED_OUTAGE_INFO)
  );
};

export const isStatusPlannedDeleted = status => {
  return status && status.status === ServicePointStatusEnum.PLANNED_OUTAGE_DELETED;
};

export const isStatusOutageEnd = status => {
  return status && status.status === ServicePointStatusEnum.OUTAGE_END;
};

export const isServicePointOrOtherSelected = (selectedSp, selectedOtherSp) => {
  return selectedOtherSp || (!selectedOtherSp && !!selectedSp.meterEic && !!selectedSp.address);
};

export const getSpActiveClass = (servicePoint, selectedServicePoint) => {
  return servicePoint.meterEic === selectedServicePoint.meterEic ? 'active' : '';
};

export const hasActiveOutage = servicePoint => {
  return givenStatusActiveAndMsgStartOrInfo(servicePoint.status) ? 'outage' : '';
};

export const isMessageTypeEnd = message => {
  return message && message.interruptionEventType === ServicePointEventType.OUTAGE_END;
};

export const setCookie = (key, value) => {
  const maxAge = 86400 * 365 * 10; // 10 years
  document.cookie = `${key}=${value}; secure; max-age=${maxAge}`;
};

export const getCookie = key => {
  try {
    return document.cookie
      .split('; ')
      .find(cookie => cookie.startsWith(`${key}=`))
      .split('=')[1];
  } catch (error) {
    return null;
  }
};

export const isMapStepNotActive = (location, activeStep) => {
  return (
    ROUTE_PATHS.DANGER_REPORT !== location.pathname ||
    !(activeStep === NotifySteps.DANGER_MAP_STEP || activeStep === NotifySteps.DANGER_RISK_MAP_STEP)
  );
};

export const isOutageMapActiveAndNotMobile = (location, isMobile) => {
  return ROUTE_PATHS.MAP === location.pathname && !isMobile;
};

export const showFooter = (location, activeStep, isMobile) => {
  return !isOutageMapActiveAndNotMobile(location, isMobile) && isMapStepNotActive(location, activeStep);
};

export const newListFromExisting = (existingList, indexes) => {
  const resultList = [];
  indexes.map(index => {
    resultList.push(existingList[index]);
  });
  return resultList;
};

export const resizeFile = file =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      2000, // Max width
      2000, // Max height
      'JPEG', // Compress format
      45, // Quality
      0, // Rotation
      uri => {
        resolve(uri);
      },
      'JPEG', // Output type
      1, // Min width
      1 // Min height
    );
  });

export const resizeImageList = async imageList => {
  for (const x in imageList) {
    try {
      const file = imageList[x].file;
      if (!imageList[x].resized) {
        imageList[x].resized = await resizeFile(file);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return imageList;
};

export const hasAddressObj = location => {
  return !!(location.address && location.addressObjId && location.x && location.y);
};

export const getMsgTimeStartEnd = (startFormat, startTime, endTime) => {
  if (!startTime) {
    return '';
  }
  return `${moment(startTime).format(startFormat)} - ${endTime ? moment(endTime).format(getEndTimeFormat(startTime, endTime)) : ''}`;
};

const getEndTimeFormat = (startTime, endTime) => {
  return moment(startTime).isSame(endTime, 'day') ? timeFormat : dateTimeFormat;
};

export const getMsgDate = (startTime, format) => {
  if (!startTime) {
    return '';
  }
  return moment(startTime).format(format);
};

export const sortArrayByDate = list => {
  return list.sort(function (a, b) {
    return new Date(b.startTime) - new Date(a.startTime);
  });
};

export const sortArrayByDateDesc = list => {
  return list.sort(function (a, b) {
    return new Date(a.startTime) - new Date(b.startTime);
  });
};

export const getHoursDiff = (start, end) => {
  const startTime = moment(start);
  const endTime = moment(end);
  const duration = moment.duration(startTime.diff(endTime));
  return duration.asHours();
};
