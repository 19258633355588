import i18n from '../i18n';
import { ErrorTexts } from '../Helpers/Enums';

export function handleError(res) {
  if (res) {
    switch (res.status) {
      case 400:
        return i18n.t(ErrorTexts.bad);
      case 401:
      case 403:
        return i18n.t(ErrorTexts.unauthorized);
      case 500:
        return i18n.t(ErrorTexts.technical);
      case 404:
      case 503:
        return i18n.t(ErrorTexts.unavailable);
      default:
        return i18n.t(ErrorTexts.main);
    }
  } else {
    return i18n.t(ErrorTexts.main);
  }
}
