import { put } from 'redux-saga/effects';

import { getLogger } from '../Logger/loggerSlice';
import { handleError } from '../../Service/HttpResponseHandler';
import { setApiErrorMessage } from '../Common/commonSlice';
import { API_ENDPOINTS, getData } from '../../Service/FetchService';
import { addressApiSuccess, endAddressApiCall } from './addressApiSlice';

export function* readAddressesFromApi(action) {
  const { payload } = action;
  const { address, x, y } = payload;

  try {
    const response = yield getData(getUrl(address, x, y));
    if (response.status === 200) {
      const data = yield response.data;
      yield put(addressApiSuccess(data));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
    yield put(getLogger({ logger: 'readAddressesFromApi', data: { request: getUrl(address, x, y), response: response.data } }));
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endAddressApiCall());
}

const getUrl = (address, x, y) => {
  if (address && !x && !y) {
    return `${API_ENDPOINTS.ADDRESS_GEOCODE}?address=${address}`;
  } else {
    return `${API_ENDPOINTS.ADDRESS_GEOCODE_COORDS}?x=${x}&y=${y}`;
  }
};
