import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

import ForwardBtn from '../../Button/ForwardBtn/ForwardBtn';
import { FeedbackModalSuccessStyles } from './FeedbackModalSuccess.styles';

function FeedbackSuccess(props) {
  const { t } = useTranslation();
  const { onClick } = props;

  return (
    <FeedbackModalSuccessStyles>
      <e-icon icon='tick' size={'md'} color={'primary'} />
      <h3 className='feedback-success-text'>{t('more.cards.app_problem.success')}</h3>
      <div className='feedback-success-btn'>
        <ForwardBtn label={t('reporting.danger_report.step.success.btn')} onClick={onClick} />
      </div>
    </FeedbackModalSuccessStyles>
  );
}

FeedbackSuccess.propTypes = {
  onClick: PropType.func,
};

export default FeedbackSuccess;
