import { createSlice } from '@reduxjs/toolkit';

const lastReadingSlice = createSlice({
  name: 'lastReading',
  initialState: {
    lastReading: [],
    unreadMessagesCount: 0,
    lastReadingLoading: false,
  },
  reducers: {
    clearLastReading(state) {
      return {
        ...state,
        lastReading: [],
        unreadMessagesCount: 0,
        lastReadingLoading: false,
      };
    },
    getLastReading(state) {
      return {
        ...state,
        lastReadingLoading: true,
      };
    },
    lastReadingSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        lastReading: payload,
        unreadMessagesCount: countUnreadMessages(payload),
      };
    },
    endLastReadingCall(state) {
      return {
        ...state,
        lastReadingLoading: false,
      };
    },
  },
});

const countUnreadMessages = messages => {
  let counter = 0;
  messages.filter(msg => {
    if (msg.hasUnreadMessages) {
      counter++;
    }
  });
  return counter;
};

export const { clearLastReading, getLastReading, lastReadingSuccess, endLastReadingCall } = lastReadingSlice.actions;

export default lastReadingSlice.reducer;
