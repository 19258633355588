import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

function getLoadPath() {
  return `${process.env.PUBLIC_URL}/translations/{{lng}}.json`;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'et',
    backend: {
      /* translation file path */
      loadPath: getLoadPath(),
    },
    fallbackLng: ['et', 'en', 'ru'],
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      formatSeparator: ',',
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
