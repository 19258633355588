import React from 'react';
import { NavLink } from 'react-router-dom';
import PropType from 'prop-types';
import { useDispatch } from 'react-redux';

import { GaEvent, GaAction, GaEvents } from '../../Helpers/GoogleAnalytics';
import { setActiveServicePoint } from '../../Store/ServicePoints/servicePointsSlice';

function NavbarLink(props) {
  const dispatch = useDispatch();
  const { path, icon, hasLabel, label, gaLabel, unreadMessagesCount } = props;

  return (
    <NavLink
      to={path}
      className='nav--item'
      onClick={() => {
        GaEvent(GaEvents.navigation, { group: GaAction.Menu, action: gaLabel });
        dispatch(setActiveServicePoint({}));
      }}
      data-testid={gaLabel}
    >
      <li className='nav--item--content'>
        {unreadMessagesCount > 0 && (
          <div className='button-badge'>
            <span className='badge'>{unreadMessagesCount}</span>
          </div>
        )}
        <svg className='icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#${icon}`} />
        </svg>
        {hasLabel && <span className='tab-text margin-left-xs text-bold'>{label}</span>}
      </li>
    </NavLink>
  );
}

NavbarLink.propTypes = {
  path: PropType.string,
  icon: PropType.string,
  hasLabel: PropType.bool,
  label: PropType.string,
  activePaths: PropType.array,
  gaLabel: PropType.string,
  unreadMessagesCount: PropType.number,
};

export default NavbarLink;
