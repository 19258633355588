import React, { lazy, Suspense } from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ModalHeader from '../ModalHeader';
import ModalContent from '../ModalContent';
import { setPrivacyModalOpen } from '../../../Store/Common/commonSlice';

const ResponsivePDF = lazy(() => import('../../ResponsivePDF/ResponsivePDF'));

function PrivacyModal() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLanguage = i18n.language;

  const { isMobile, privacyModalOpen } = useSelector(state => state.common);

  const handleClose = () => {
    dispatch(setPrivacyModalOpen(false));
  };

  return (
    <Dialog open={privacyModalOpen} onClose={handleClose} fullScreen={isMobile} fullWidth maxWidth={'md'}>
      <div className='dialog--container'>
        <ModalHeader headerText={t('more.cards.privacy')} handleClose={handleClose} showCloseBtn={true} />
        <hr className='dialog--separator' />
        <ModalContent>
          <Suspense fallback=''>
            <ResponsivePDF file={`${process.env.PUBLIC_URL}/assets/files/terms/maru_tingimused_${currentLanguage}.pdf`} />
          </Suspense>
        </ModalContent>
      </div>
    </Dialog>
  );
}

export default PrivacyModal;
