import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NotifySteps } from '../../../Helpers/Enums';
import { useSelector } from 'react-redux';

function ReportingBackBtn(props) {
  const { onClick } = props;
  const { t } = useTranslation();

  const { isMobile } = useSelector(state => state.common);
  const { activeStep } = useSelector(state => state.commonNotify);
  const { servicePoints } = useSelector(state => state.servicePoints);

  const getHeaderText = () => {
    switch (activeStep) {
      case NotifySteps.SP_SELECTION_STEP:
        return t('reporting.cards.noPower.header');
      case NotifySteps.DANGER_INFO_STEP:
      case NotifySteps.DANGER_RISK_INFO_STEP:
      case NotifySteps.DANGER_CONFIRMATION_STEP:
      case NotifySteps.DANGER_RISK_MAP_STEP:
      case NotifySteps.DANGER_MAP_STEP:
        return t('reporting.danger_report.step.danger_info.header');
      case NotifySteps.ADDRESS_CONFIRMATION_STEP:
        return servicePoints.length > 0 ? t('reporting.outage_report.step.sp_selection.header') : t('reporting.cards.noPower.header');
      case NotifySteps.OUTAGE_INFO_STEP:
        return t('reporting.outage_report.step.address_conf.header');
      default:
        return t('header.navigation.report');
    }
  };

  const getTextSize = () => {
    return isMobile ? 'text-sm' : 'text-xl';
  };

  return (
    <div onClick={onClick} className='header--container pointer' data-testid='handleBackBtnClick'>
      <div className='arrow--logo'>
        <e-icon icon='arrow-left' size='sm' color='white' />
      </div>
      <h1 className={`no-margin-bottom ${getTextSize()}`}>{getHeaderText()}</h1>
    </div>
  );
}

ReportingBackBtn.propTypes = {
  onClick: PropType.func,
};

export default ReportingBackBtn;
