import styled from 'styled-components';

const getPadding = params => {
  if (params.noPadding) {
    return '0';
  } else {
    return '2em 0 0 0';
  }
};

export const ForwardBtnStyles = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => getPadding(props)};

  button {
    min-width: 15em;
    max-width: 20em;
    pointer-events: auto;
  }

  .e-btn:disabled {
    background-image: none;
  }
`;
