import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeDropdown } from '../../../Helpers/HelperMethods';
import { getParsedToken } from '../../../keycloak';
import { LogoutBtnStyles } from './LogoutBtn.styles';
import { setRoleModalOpen } from '../../../Store/CustomerInfo/customerInfoSlice';

function LogoutBtn(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { logout } = props;

  const { isMobile } = useSelector(state => state.common);
  const { customerInfoActive, customerInfo, customerInfoLoading, roleModalOpen } = useSelector(state => state.customerInfo);

  const openRoleModal = () => {
    if (!(customerInfo && customerInfo.roles && customerInfo.roles.length > 0)) {
      return;
    }
    dispatch(setRoleModalOpen(!roleModalOpen));
    closeDropdown('logoutDropdown');
  };

  const getName = () => {
    return customerInfoActive.name ? customerInfoActive.name : getParsedToken().name;
  };

  return (
    <React.Fragment>
      <LogoutBtnStyles>
        <div className='flex flex-gap-md'>
          {!customerInfoLoading && (
            <e-dropdown text={!isMobile ? getName() : ''} type='button' id='logoutDropdown' button-color='gradient-primary'>
              <e-icon slot='button-icon' size='xs' />
              {isMobile && <span>{getName()}</span>}
              {customerInfo && customerInfo.roles && customerInfo.roles.length > 0 && (
                <e-button onClick={() => openRoleModal()} size='sm' data-testid='openRoleModalBtn'>
                  <span>{t('header.changeRole')}</span>
                </e-button>
              )}
              <e-button onClick={logout} size='sm' data-testid='logoutBtn'>
                <span>{t('header.logout')}</span>
              </e-button>
            </e-dropdown>
          )}
        </div>
      </LogoutBtnStyles>
    </React.Fragment>
  );
}

LogoutBtn.propTypes = {
  logout: PropType.func.isRequired,
};

export default LogoutBtn;
