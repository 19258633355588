import { lazy } from 'react';

const MapView = lazy(() => import('../Views/Map/MapView'));
const InfoView = lazy(() => import('../Views/Info/InfoView'));
const WeatherView = lazy(() => import('../Views/Weather/WeatherView'));
const NotFoundView = lazy(() => import('../Views/NotFound/NotFoundView'));
const MessagesView = lazy(() => import('../Views/Messages/MessagesView'));
const DangerReport = lazy(() => import('../Views/Reporting/DangerReport/DangerReport'));
const OutageReport = lazy(() => import('../Views/Reporting/OutageReport/OutageReport'));
const ReportingView = lazy(() => import('../Views/Reporting/ReportingView'));

export const ROUTE_PATHS = {
  INDEX: '/',
  MESSAGES: '/messages',
  REPORTING: '/reporting',
  DANGER_REPORT: '/reporting/danger',
  OUTAGE_REPORT: '/reporting/outage',
  MAP: '/map',
  WEATHER: '/weather',
  INFO: '/info',
  SOMETHING_ELSE: '/*',
  MAINTENANCE: '/maintenance',
};

export const routes = [
  { path: ROUTE_PATHS.INDEX, component: MessagesView, exact: true },
  { path: ROUTE_PATHS.MESSAGES, component: MessagesView, exact: true },
  { path: ROUTE_PATHS.REPORTING, component: ReportingView, exact: true },
  { path: ROUTE_PATHS.DANGER_REPORT, component: DangerReport, exact: true },
  { path: ROUTE_PATHS.OUTAGE_REPORT, component: OutageReport, exact: true },
  { path: ROUTE_PATHS.MAP, component: MapView, exact: true },
  { path: ROUTE_PATHS.WEATHER, component: WeatherView, exact: true },
  { path: ROUTE_PATHS.INFO, component: InfoView, exact: true },
  { path: ROUTE_PATHS.SOMETHING_ELSE, component: NotFoundView, exact: true },
  { path: ROUTE_PATHS.MAINTENANCE, component: MessagesView, exact: true },
];
