import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useNetwork from '../../Service/useNetwork';
import NoticeError from './NoticeError';
import NoticeInfoText from './NoticeInfoText';
import { NoticeTypes } from '../../Helpers/Enums';
import { NoticeCompStyles } from './NoticeComp.styles';
import { setRegistrationTokenSuccess } from '../../Store/Notification/notificationSlice';
import { setCustomerLanguageSuccessStatus } from '../../Store/CustomerInfo/customerInfoSlice';

function NoticesComp() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();

  const { apiErrorMessage } = useSelector(state => state.common);
  const { customerLanguageSuccess } = useSelector(state => state.customerInfo);
  const { registrationTokenSuccess } = useSelector(state => state.notification);

  const isOnline = useNetwork();

  useEffect(() => {
    dispatch(setCustomerLanguageSuccessStatus(false));
    dispatch(setRegistrationTokenSuccess(false));
  }, [location]);

  return (
    <NoticeCompStyles>
      {apiErrorMessage && <NoticeError />}
      {customerLanguageSuccess && <NoticeInfoText type={NoticeTypes.success} label={t('success.lang_changed')} />}
      {!isOnline && <NoticeInfoText type={NoticeTypes.error} label={t('error.no_network')} />}
      {registrationTokenSuccess.success && (
        <NoticeInfoText type={NoticeTypes.success} label={t(`success.notification_registered.${registrationTokenSuccess.type}`)} />
      )}
    </NoticeCompStyles>
  );
}

export default NoticesComp;
