import { css, createGlobalStyle } from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';

const modalStyles = css`
  .dialog--container {
    padding: 1.5em;
  }

  .modal--header {
    display: flex;
    color: ${Colors.text.regular};
    h3 {
      margin-bottom: 0;
    }
  }

  .modal--header--center {
    justify-content: center;
  }

  .modal--header--between {
    justify-content: space-between;
  }

  .dialog--separator {
    margin: 1.5em -1.5em;
    background-color: ${Colors.background.gray};
  }

  .content--item--container {
    border: 1px solid transparent;
    border-radius: 0.5em;
    cursor: pointer;
    margin-bottom: 0.3em;
    padding: 0.5em;
    h5 {
      color: ${Colors.blue.primary};
    }
    span {
      color: ${Colors.text.additionalDark};
    }
    :hover {
      border: 1px solid ${Colors.blue.primary};
    }
  }

  .content--item-row {
    display: flex;
    align-items: center;
    e-icon .icon.icon--sm {
      font-size: 2rem;
      margin-right: 0.3em;
    }
  }

  .content--item--active {
    border: 1px solid ${Colors.blue.primary};
  }

  .feedback-error-texts {
    margin: 1em 0 2em 0;
  }

  .modal-content-email-container {
    padding-top: 1.5em;
  }

  .modal-content-email-input {
    padding-top: 0.5em;
  }

  .modal-content-photo-instruction {
    justify-content: space-between;
    display: flex;
  }

  .custom-icon {
    fill: currentColor;
    height: 0.75em;
    width: 0.75em;
    font-size: 2rem;
  }
`;

export const ModalStyles = createGlobalStyle`
${modalStyles}`;
