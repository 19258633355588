import { createSlice } from '@reduxjs/toolkit';

const imageUploadSlice = createSlice({
  name: 'imageUpload',
  initialState: {
    imagesUploading: false,
    uploadedImages: [],
    sendWhileLoadingErr: false,
    imageUploadError: false,
  },
  reducers: {
    clearImages(state) {
      return {
        ...state,
        imagesUploading: false,
        uploadedImages: [],
        sendWhileLoadingErr: false,
        imageUploadError: false,
      };
    },
    uploadImages(state) {
      return {
        ...state,
        imagesUploading: true,
        imageUploadError: false,
      };
    },
    uploadImagesSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        uploadedImages: [...state.uploadedImages, payload],
      };
    },
    uploadImagesFailure(state) {
      return {
        ...state,
        imagesUploading: false,
        imageUploadError: true,
      };
    },
    setImagesUploading(state, action) {
      const { payload } = action;
      return {
        ...state,
        imagesUploading: payload,
      };
    },
    setImageUploadError(state, action) {
      const { payload } = action;
      return {
        ...state,
        imageUploadError: payload,
      };
    },
    removeFromUploadedImages(state, action) {
      const { payload } = action;
      const updatedImagesList = removeFromArray(payload, state);
      return {
        ...state,
        uploadedImages: updatedImagesList,
      };
    },
    setSendWhileLoadingErr(state, action) {
      const { payload } = action;
      return {
        ...state,
        sendWhileLoadingErr: payload,
      };
    },
  },
});

export const getUploadedImagesIds = state => {
  const tmpArray = [];
  state.imageUpload.uploadedImages.forEach(item => {
    tmpArray.push(item.uuid);
  });
  return tmpArray;
};

export const getUploadedImages = state => {
  return state.imageUpload.uploadedImages;
};

const removeFromArray = (image, state) => {
  const imagesArray = [...state.uploadedImages];
  const existingImage = imagesArray.find(item => item.data_url === image.data_url);
  const index = imagesArray.indexOf(existingImage);
  if (index > -1) {
    imagesArray.splice(index, 1);
  }
  return imagesArray;
};

export const {
  clearImages,
  uploadImages,
  setImagesUploading,
  uploadImagesFailure,
  uploadImagesSuccess,
  setImageUploadError,
  setSendWhileLoadingErr,
  removeFromUploadedImages,
} = imageUploadSlice.actions;

export default imageUploadSlice.reducer;
