import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import LogoutBtn from '../../Button/LogoutBtn/LogoutBtn';
import HeaderBackBtn from '../Navigation/HeaderBackBtn';
import { ROUTE_PATHS } from '../../../Routes/Routes';
import { hasServicePoint } from '../../../Helpers/HelperMethods';
import { HeaderMobileStyles } from './HeaderMobile.styles';
import { setActiveServicePoint } from '../../../Store/ServicePoints/servicePointsSlice';

function HeaderMobile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { activeServicePoint, servicePoints } = useSelector(state => state.servicePoints);
  const { activeStep } = useSelector(state => state.commonNotify);

  const { login, logout, isAuthenticated } = props;

  const clearActiveServicePoint = () => {
    dispatch(setActiveServicePoint({}));
  };
  return (
    <HeaderMobileStyles>
      <e-section background-color='gradient-accent'>
        <e-section-block class='header-text-container'>
          <div className='header--container'>
            {hasServicePoint(activeServicePoint) && servicePoints.length > 1 ? (
              <div className='header--container' onClick={clearActiveServicePoint} data-testid='clearServicePointBtn'>
                <div className='arrow--logo'>
                  <e-icon icon='arrow-left' size='sm' color='white' />
                </div>
                <h1 className='text-xl margin-left-xxxs no-margin-bottom pointer'>{t('messages.my_messages')}</h1>
              </div>
            ) : (
              <HeaderBackBtn
                activeStep={activeStep}
                originalBackBtn={
                  <div className='grid items-center'>
                    <div className='col-1'>
                      <a href={t('homepage')} className='main-page-link' data-testid='goToElvMainPageBtn'>
                        <div className='header--logo'>
                          <e-icon icon='elv' size='sm' color='white' />
                        </div>
                      </a>
                    </div>
                    <div className='col-8 title-padding'>
                      <Link to={ROUTE_PATHS.MESSAGES} className='main-page-link'>
                        <h1 className='text-md margin-left-xxxs no-margin-bottom pointer'>{t('title')}</h1>
                      </Link>
                    </div>
                  </div>
                }
              />
            )}

            <div className='right'>
              {!isAuthenticated && (
                <e-button icon-button color='gradient-primary' onClick={login} data-testid='loginBtn'>
                  <e-icon icon='person' />
                </e-button>
              )}
              {isAuthenticated && <LogoutBtn logout={logout} />}
            </div>
          </div>
        </e-section-block>
      </e-section>
    </HeaderMobileStyles>
  );
}

HeaderMobile.propTypes = {
  login: PropType.func,
  logout: PropType.func,
  isAuthenticated: PropType.bool,
};

export default HeaderMobile;
