import { createSlice } from '@reduxjs/toolkit';
import { NotificationSend } from '../../Helpers/Enums';

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notificationToken: '',
    registrationLoading: false,
    registrationCheckLoading: false,
    registrationTokenSuccess: { success: false, type: NotificationSend.subscribe },
  },
  reducers: {
    setNotificationToken(state, action) {
      const { payload } = action;
      return {
        ...state,
        notificationToken: payload,
        registrationLoading: false,
        registrationCheckLoading: false,
      };
    },
    validateRegistration(state) {
      return {
        ...state,
        registrationCheckLoading: true,
      };
    },
    subscribeOrUnsubscribeToNotification(state) {
      return {
        ...state,
        registrationLoading: true,
      };
    },
    setRegistrationTokenSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        registrationTokenSuccess: payload,
      };
    },
  },
});

export const { subscribeOrUnsubscribeToNotification, setNotificationToken, validateRegistration, setRegistrationTokenSuccess } =
  notificationSlice.actions;

export default notificationSlice.reducer;
