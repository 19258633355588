import React from 'react';
import { useSelector } from 'react-redux';

import FooterMobile from './Mobile/FooterMobile';
import FooterDesktop from './Desktop/FooterDesktop';

function Footer() {
  const { isMobile } = useSelector(state => state.common);

  return <React.Fragment>{!isMobile ? <FooterDesktop /> : <FooterMobile />}</React.Fragment>;
}

export default Footer;
