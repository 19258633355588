import styled from 'styled-components';

export const HeaderMobileStyles = styled.div`
  .header-text-container .content-wrapper {
    padding: 0.7em;
  }

  .header--logo {
    e-icon .icon.icon--default,
    e-icon .icon.icon--sm {
      font-size: 2.3rem;
    }
  }

  e-button .e-btn {
    font-size: 0.8em;
  }

  .right {
    position: absolute;
    right: 10px;
  }

  .title-padding {
    padding-left: 0.2em;
  }
`;
