import { createSlice } from '@reduxjs/toolkit';

const addressApiSlice = createSlice({
  name: 'addressApi',
  initialState: {
    addressApiResult: [],
    addressApiLoading: false,
  },
  reducers: {
    addressApi(state) {
      return {
        ...state,
        addressApiLoading: true,
      };
    },
    endAddressApiCall(state) {
      return {
        ...state,
        addressApiLoading: false,
      };
    },
    addressApiSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        addressApiResult: payload,
        addressApiLoading: false,
      };
    },
    clearAddressApiResult(state) {
      return {
        ...state,
        addressApiResult: [],
        addressApiLoading: false,
      };
    },
  },
});

export const { addressApi, addressApiSuccess, endAddressApiCall, clearAddressApiResult } = addressApiSlice.actions;

export default addressApiSlice.reducer;
