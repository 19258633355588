import { API_ENDPOINTS, postData } from '../../Service/FetchService';

export function* sendLogger(action) {
  const { payload } = action;
  const { logger, data } = payload;

  const body = {
    logger: logger,
    data: data,
  };

  try {
    yield postData(API_ENDPOINTS.DUMMY_INGRESS_ENDPOINT, body);
  } catch (error) {
    console.log(error);
  }
}
