import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import { PersistGate } from 'redux-persist/integration/react';
import { isIOS } from 'react-device-detect';
import reportWebVitals from './reportWebVitals';
import App from './Components/App/App';
import InstallBanner from './Components/InstallBanner/InstallBanner';
import { store, persistor } from './Store/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';

moment.locale('et');

ReactDOM.render(
  <Suspense fallback=''>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    {!isIOS && <InstallBanner />}
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: registration => {
    console.debug('Service worker update');

    const activeWorker = registration.active;
    if (activeWorker && activeWorker.state === 'activated') {
      console.debug('Service worker updated, reloading page..');
      window.location.reload();
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
