import React from 'react';

import Navbar from '../../Navbar/Navbar';
import { FooterMobileStyles } from './FooterMobile.styles';

function FooterMobile() {
  return (
    <FooterMobileStyles>
      <hr />
      <e-section background-color='white'>
        <Navbar />
      </e-section>
    </FooterMobileStyles>
  );
}

export default FooterMobile;
