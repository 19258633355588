import { createSlice } from '@reduxjs/toolkit';

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    feedbackSuccess: false,
    feedbackSent: false,
    feedback: '',
    senderEmail: '',
  },
  reducers: {
    sendFeedback(state) {
      return {
        ...state,
      };
    },
    setFeedbackSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        feedbackSuccess: payload.success,
        feedbackSent: payload.sent,
        senderEmail: '',
        feedback: '',
      };
    },
    setSenderEmail(state, action) {
      const { payload } = action;
      return {
        ...state,
        senderEmail: payload,
      };
    },
    setFeedback(state, action) {
      const { payload } = action;
      return {
        ...state,
        feedback: payload,
      };
    },
  },
});

export const getFeedback = state => {
  return state.feedback.feedback;
};

export const getUserEmail = state => {
  return state.feedback.senderEmail;
};

export const { sendFeedback, setFeedbackSuccess, setSenderEmail, setFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
