import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useMediaQuery } from 'react-responsive';

import AppMain from '../AppMain/AppMain';
import AppLoading from '../Loading/AppLoading';
import { setIsMobile } from '../../Store/Common/commonSlice';
import { ModalStyles } from '../Modal/Modal.styles';
import { ResponsiveWidths } from '../../Helpers/Enums';
import { GaEvent, GaAction, GaEvents } from '../../Helpers/GoogleAnalytics';
import { keycloak, keycloakInitOptions, keycloakEvent } from '../../keycloak';

function App() {
  const dispatch = useDispatch();

  const isMobileWidth = useMediaQuery({ query: ResponsiveWidths.mobile });

  useEffect(() => {
    dispatch(setIsMobile(isMobileWidth));
  }, [isMobileWidth, dispatch]);

  if (window.performance) {
    if (performance && performance.navigation && performance.navigation.type === 1) {
      GaEvent(GaEvents.navigation, { group: GaAction.Header, action: GaAction.Refresh });
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakInitOptions}
      LoadingComponent={<AppLoading />}
      onEvent={keycloakEvent}
      autoRefreshToken={false}
    >
      <ModalStyles />
      <BrowserRouter>
        <AppMain />
      </BrowserRouter>
    </ReactKeycloakProvider>
  );
}

export default App;
