import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import RootSaga from './Saga';
import MapReducer from './Map/mapSlice';
import CommonReducer from './Common/commonSlice';
import LoggerReducer from './Logger/loggerSlice';
import MessagesReducer from './Messages/messagesSlice';
import FeedbackReducer from './Feedback/feedbackSlice';
import AppVersionReducer from './AppVersion/appVersionSlice';
import AddressApiReducer from './AddressApi/addressApiSlice';
import NotifyFetchReducer from './Notify/notifyFetchSlice';
import MessageInfoReducer from './Messages/messageInfoSlice';
import LastReadingReducer from './LastReading/lastReadingSlice';
import ImageUploadReducer from './ImageUpload/imageUploadSlice';
import OutageNotifyReducer from './Notify/outageNotifySlice';
import CommonNotifyReducer from './Notify/commonNotifySlice';
import DangerNotifyReducer from './Notify/dangerNotifySlice';
import CustomerInfoReducer from './CustomerInfo/customerInfoSlice';
import ServicePointReducer from './ServicePoints/servicePointsSlice';
import NotificationReducer from './Notification/notificationSlice';
import QuestionnaireReducer from './Notify/questionnaireSlice';
import MessageStatusReducer from './Messages/messagesStatusSlice';
import OutageUpdatePowerIsBack from './Notify/outageUpdatePowerIsBackSlice';

const sagaMiddleware = createSagaMiddleware();
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  map: MapReducer,
  common: CommonReducer,
  logger: LoggerReducer,
  messages: MessagesReducer,
  feedback: FeedbackReducer,
  notify: NotifyFetchReducer,
  appVersion: AppVersionReducer,
  addressApi: AddressApiReducer,
  imageUpload: ImageUploadReducer,
  lastReading: LastReadingReducer,
  messageInfo: MessageInfoReducer,
  customerInfo: CustomerInfoReducer,
  outageNotify: OutageNotifyReducer,
  commonNotify: CommonNotifyReducer,
  dangerNotify: DangerNotifyReducer,
  notification: NotificationReducer,
  servicePoints: ServicePointReducer,
  questionnaire: QuestionnaireReducer,
  messageStatus: MessageStatusReducer,
  outageUpdatePowerIsBack: OutageUpdatePowerIsBack,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['customerInfo'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
  sagaMiddleware,
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

sagaMiddleware.run(RootSaga);

export default store;
