import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NoticeTypes } from '../../Helpers/Enums';
import { NoticeStyles } from './Notice.styles';
import { setApiErrorMessage } from '../../Store/Common/commonSlice';

function NoticeError() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const closeNotice = () => {
    dispatch(setApiErrorMessage(''));
  };

  return (
    <NoticeStyles type={NoticeTypes.error} align='center'>
      <span className='notify__error'>{t('error.overall_error')}</span>
      <div className='close-btn'>
        <e-button onClick={() => closeNotice()} color='transparent' icon-button is-link data-testid='noticeErrorCloseBtn'>
          <e-icon icon='tick' />
        </e-button>
      </div>
    </NoticeStyles>
  );
}

export default NoticeError;
