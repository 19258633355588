import React, { useEffect, useState } from 'react';

function useNetwork() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const updateNetWorkStatus = () => {
    setIsOnline(window.navigator.onLine);
  };

  useEffect(() => {
    window.addEventListener('change', updateNetWorkStatus);
    window.addEventListener('offline', updateNetWorkStatus);
    window.addEventListener('online', updateNetWorkStatus);
    return () => {
      window.addEventListener('change', updateNetWorkStatus);
      window.addEventListener('offline', updateNetWorkStatus);
      window.addEventListener('online', updateNetWorkStatus);
    };
  });

  return isOnline;
}

export default useNetwork;
