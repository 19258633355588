import { createSlice } from '@reduxjs/toolkit';

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    questionnaireResult: {},
  },
  reducers: {
    setQuestionnaireResult(state, action) {
      const { payload } = action;
      return {
        ...state,
        questionnaireResult: payload,
      };
    },
    clearQuestionnaireResult(state) {
      return {
        ...state,
        questionnaireResult: {},
      };
    },
  },
});

export const getQuestionnaireResult = state => {
  return state.questionnaire.questionnaireResult;
};

export const { setQuestionnaireResult, clearQuestionnaireResult } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
