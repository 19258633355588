import React from 'react';
import { InstallBannerStyles } from './InstallBanner.styles';
import { useTranslation } from 'react-i18next';
import { isIOS } from 'react-device-detect';

import { GaEvent, GaAction, GaEvents } from '../../Helpers/GoogleAnalytics';

let deferredPrompt;
let installDiv = document.getElementById('installBannerDiv');
let installBtn = document.getElementById('installBannerBtn');

window.addEventListener('beforeinstallprompt', e => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  installDiv = document.getElementById('installBannerDiv');
  installBtn = document.getElementById('installBannerBtn');
  if (!isIOS) {
    installDiv.style.display = 'block';
    installBtn.addEventListener('click', function (mouseEvent) {
      GaEvent(GaEvents.navigation, { group: GaAction.PWA, action: GaAction.addToScreen });
      // you should not use the MouseEvent here, obviously
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          installDiv.style.display = 'none';
        } else {
          console.log('User dismissed the A2HS prompt');
          installDiv.style.display = 'none';
        }
        deferredPrompt = null;
      });
    });
  }
});

window.onappinstalled = function (ev) {
  console.log('The application was installed.');
  installDiv = document.getElementById('installBannerDiv');
  setTimeout(() => {
    installDiv.style.display = 'none';
  }, 2000);
  installDiv.style.display = 'none';
};

function InstallBanner() {
  const { t } = useTranslation();

  const close = () => {
    GaEvent(GaEvents.navigation, { group: GaAction.PWA, action: GaAction.Close });
    const element = document.getElementById('installBannerDiv');
    if (element) {
      element.style.display = 'none';
    }
  };

  return (
    <InstallBannerStyles>
      <div id='installBannerDiv' data-testid='installBannerDiv'>
        <div className='banner-header'>
          <div className='flex items-center'>
            <img className='elv-icon' src={`${process.env.PUBLIC_URL}/assets/images/icon-512x512.png`} alt='maru_logo' />
            <div>
              <h4 className='no-margin-bottom text-bold'>{t('installBanner.title')}</h4>
              <p className='no-margin-bottom'>{t('installBanner.homepage')}</p>
            </div>
          </div>
          <div id='closeBtn'>
            <e-button icon-button color='transparent' onClick={close} data-testid='closeInstallBtn'>
              <e-icon icon='close' />
            </e-button>
          </div>
        </div>
        <div className='flex justify-end'>
          <button id='installBannerBtn' data-testid='installBannerBtn'>
            {t('installBanner.addToHomepage')}
          </button>
        </div>
      </div>
    </InstallBannerStyles>
  );
}

export default InstallBanner;
