import { createSlice } from '@reduxjs/toolkit';

const outageNotifySlice = createSlice({
  name: 'outageNotify',
  initialState: {
    selectedServicePoint: {},
    selectedOther: false,
    selectedAddress: {},
    hasServiceFeeAgreement: false,
    hasValidationError: false,
    isAgreementVisible: false,
    outageForm: {
      spType: undefined,
      address: {},
      phone: '',
      personName: '',
    },
    outageFormErrors: {},
    outageFormHasErrors: false,
  },
  reducers: {
    clearOutageSteps(state) {
      return {
        ...state,
        selectedServicePoint: {},
        selectedOther: false,
        selectedAddress: {},
        hasServiceFeeAgreement: false,
        hasValidationError: false,
        isAgreementVisible: false,
        outageForm: {
          spType: undefined,
          address: {},
          phone: '',
          personName: '',
        },
        outageFormErrors: {},
        outageFormHasErrors: false,
      };
    },
    setSelectedServicePoint(state, action) {
      const { payload } = action;
      return {
        ...state,
        selectedServicePoint: payload,
      };
    },
    setSelectedOther(state, action) {
      const { payload } = action;
      return {
        ...state,
        selectedOther: payload,
      };
    },
    setSelectedAddress(state, action) {
      const { payload } = action;
      return {
        ...state,
        selectedAddress: payload,
      };
    },
    setHasServiceFeeAgreement(state, action) {
      const { payload } = action;
      return {
        ...state,
        hasServiceFeeAgreement: payload,
      };
    },
    setIsAgreementVisible(state, action) {
      const { payload } = action;
      return {
        ...state,
        isAgreementVisible: payload,
      };
    },
    setHasValidationError(state, action) {
      const { payload } = action;
      return {
        ...state,
        hasValidationError: payload,
      };
    },
    setOutageFormField(state, action) {
      const { payload } = action;
      return {
        ...state,
        outageForm: { ...state.outageForm, [payload.field]: payload.value },
      };
    },
    setOutageFormErrors(state, action) {
      const { payload } = action;
      return {
        ...state,
        outageFormErrors: { ...state.outageFormErrors, ...payload },
      };
    },
    setOutageFormHasErrors(state, action) {
      const { payload } = action;
      return {
        ...state,
        outageFormHasErrors: payload,
      };
    },
  },
});

export const getAddress = state => {
  return state.outageNotify.selectedOther
    ? { address: state.outageNotify.selectedAddress.address, addressObjId: state.outageNotify.selectedAddress.addressObjId }
    : { address: state.outageNotify.selectedServicePoint.address, eic: state.outageNotify.selectedServicePoint.meterEic };
};

export const getAddressType = state => {
  return state.outageNotify.selectedOther ? state.outageNotify.selectedAddress.type : state.outageNotify.selectedServicePoint.type;
};

export const getHasServiceFeeAgreement = state => {
  return state.outageNotify.hasServiceFeeAgreement;
};

export const {
  clearOutageSteps,
  setSelectedOther,
  setOutageFormField,
  setSelectedAddress,
  setOutageFormErrors,
  setHasValidationError,
  setIsAgreementVisible,
  setOutageFormHasErrors,
  setSelectedServicePoint,
  setHasServiceFeeAgreement,
} = outageNotifySlice.actions;

export default outageNotifySlice.reducer;
