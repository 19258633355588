import { createSlice } from '@reduxjs/toolkit';

const appVersionSlice = createSlice({
  name: 'appVersion',
  initialState: {
    apiVersion: '-',
    clientVersion: '-',
    features: {
      outageReportingAllowNonAuthenticated: false,
    },
  },
  reducers: {
    getApiVersion(state) {
      return {
        ...state,
        apiVersion: '-',
      };
    },
    getClientVersion(state) {
      return {
        ...state,
      };
    },
    getFeatures(state) {
      return {
        ...state,
        features: {
          outageReportingAllowNonAuthenticated: false,
        },
      };
    },
    getFeaturesSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        features: {
          outageReportingAllowNonAuthenticated: payload,
        },
      };
    },
    getApiVersionSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        apiVersion: payload,
      };
    },
    getClientVersionSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        clientVersion: payload,
      };
    },
  },
});

export const getVersionFromState = state => {
  return state.appVersion.apiVersion;
};

export const { getApiVersion, getClientVersion, getFeatures, getApiVersionSuccess, getClientVersionSuccess, getFeaturesSuccess } =
  appVersionSlice.actions;

export default appVersionSlice.reducer;
