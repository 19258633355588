import { createSlice } from '@reduxjs/toolkit';

const customerInfoSlice = createSlice({
  name: 'customer',
  initialState: {
    customerInfo: {},
    customerInfoLoading: true,
    customerInfoActive: {},
    roleModalOpen: false,
    preSelectedRoleId: null,
    customerLanguageSuccess: false,
  },
  reducers: {
    clearCustomerInfo(state) {
      return {
        ...state,
        customerInfo: {},
        customerInfoLoading: true,
        customerInfoActive: {},
        roleModalOpen: false,
        preSelectedRoleId: null,
        customerLanguageSuccess: false,
      };
    },
    getCustomerInfo(state) {
      return {
        ...state,
        customerInfo: {},
        customerInfoLoading: true,
        customerInfoActive: {},
        roleModalOpen: false,
      };
    },
    endCustomerInfoCall(state) {
      return {
        ...state,
        customerInfoLoading: false,
      };
    },
    getCustomerInfoSuccess(state, action) {
      const { payload } = action;
      const preSelectedCustomer = activePreSelectedCustomer(payload, state);

      return {
        ...state,
        customerInfo: payload,
        customerInfoLoading: false,
        customerInfoActive: preSelectedCustomer ? preSelectedCustomer : activeCustomer(payload),
        roleModalOpen: !preSelectedCustomer && payload.roles && payload.roles.length > 0,
      };
    },
    setActiveCustomer(state, action) {
      const { payload } = action;
      return {
        ...state,
        preSelectedRoleId: null,
        customerInfoActive: activeCustomer(payload),
      };
    },
    setRoleModalOpen(state, action) {
      const { payload } = action;
      return {
        ...state,
        roleModalOpen: payload,
      };
    },
    preSelectRole(state, action) {
      const { payload } = action;
      return {
        ...state,
        preSelectedRoleId: payload.roleId,
      };
    },
    setCustomerLanguage(state) {
      return {
        ...state,
      };
    },
    setCustomerLanguageSuccessStatus(state, action) {
      const { payload } = action;
      return {
        ...state,
        customerLanguageSuccess: payload,
      };
    },
    updateCustomerLanguage(state, action) {
      const { payload } = action;
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          userSelectedLanguage: payload,
        },
      };
    },
  },
});

export const activePreSelectedCustomer = (info, state) => {
  if (!state.preSelectedRoleId) {
    return null;
  }

  if (info.name && info.id) {
    if (info.id === state.preSelectedRoleId) {
      return { name: info.name, customerId: info.id };
    }
  }

  let customer = null;

  if (info.roles) {
    info.roles.forEach(role => {
      if (role.relatedCustomerId === state.preSelectedRoleId) {
        customer = {
          name: role.name,
          customerId: role.relatedCustomerId,
        };
      }
    });
  }

  return customer;
};

const activeCustomer = info => {
  if (info.name && info.relatedCustomerId) {
    return {
      name: info.name,
      customerId: info.relatedCustomerId,
    };
  } else {
    return { name: info.name, customerId: info.id };
  }
};

export const getRelatedCustomerId = state => {
  if (state.customerInfo.customerInfo.id !== state.customerInfo.customerInfoActive.customerId) {
    return state.customerInfo.customerInfoActive.customerId;
  } else {
    return null;
  }
};

export const getCustomerId = state => {
  return state.customerInfo.customerInfo.id;
};

export const {
  preSelectRole,
  getCustomerInfo,
  setRoleModalOpen,
  setActiveCustomer,
  clearCustomerInfo,
  endCustomerInfoCall,
  setCustomerLanguage,
  updateCustomerLanguage,
  getCustomerInfoSuccess,
  setCustomerLanguageSuccessStatus,
} = customerInfoSlice.actions;

export default customerInfoSlice.reducer;
