import React from 'react';
import PropType from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ReportingBackBtn from './ReportingBackBtn';
import { ROUTE_PATHS } from '../../../Routes/Routes';
import { NotifySteps } from '../../../Helpers/Enums';
import { setActiveStep } from '../../../Store/Notify/commonNotifySlice';
import { setForceOpenSuggestions } from '../../../Store/Map/mapSlice';

function HeaderBackBtn(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { originalBackBtn } = props;

  const { servicePoints } = useSelector(state => state.servicePoints);
  const { activeStep } = useSelector(state => state.commonNotify);

  const handleBackBtnClick = () => {
    switch (activeStep) {
      case NotifySteps.SP_SELECTION_STEP:
        navigate(ROUTE_PATHS.REPORTING);
        break;
      case NotifySteps.DANGER_INFO_STEP:
      case NotifySteps.DANGER_RISK_INFO_STEP:
        dispatch(setActiveStep(NotifySteps.DANGER_CONFIRMATION_STEP));
        break;
      case NotifySteps.ADDRESS_CONFIRMATION_STEP:
        servicePoints.length > 0 ? dispatch(setActiveStep(NotifySteps.SP_SELECTION_STEP)) : navigate(ROUTE_PATHS.REPORTING);
        break;
      case NotifySteps.DANGER_CONFIRMATION_STEP:
        navigate(ROUTE_PATHS.REPORTING);
        break;
      case NotifySteps.OUTAGE_INFO_STEP:
        dispatch(setActiveStep(NotifySteps.ADDRESS_CONFIRMATION_STEP));
        break;
      case NotifySteps.DANGER_MAP_STEP:
        dispatch(setActiveStep(NotifySteps.DANGER_INFO_STEP));
        dispatch(setForceOpenSuggestions(false));
        break;
      case NotifySteps.DANGER_RISK_MAP_STEP:
        dispatch(setActiveStep(NotifySteps.DANGER_RISK_INFO_STEP));
        dispatch(setForceOpenSuggestions(false));
        break;
      default:
        navigate(ROUTE_PATHS.REPORTING);
    }
  };

  const isReportPath = () => {
    return location.pathname === ROUTE_PATHS.OUTAGE_REPORT || location.pathname === ROUTE_PATHS.DANGER_REPORT;
  };

  const reportingBackButton = () => {
    return <ReportingBackBtn onClick={() => handleBackBtnClick()} />;
  };

  return <React.Fragment>{isReportPath() ? reportingBackButton() : originalBackBtn}</React.Fragment>;
}

HeaderBackBtn.propTypes = {
  originalBackBtn: PropType.element,
};

export default HeaderBackBtn;
