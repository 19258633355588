import React from 'react';
import { useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import HeaderMobile from './Mobile/HeaderMobile';
import HeaderDesktop from './Desktop/HeaderDesktop';
import { GaEvent, GaAction, GaEvents } from '../../Helpers/GoogleAnalytics';
import { doLogout, doLogin } from '../../keycloak';
import { HeaderStyles } from './Header.styles';

function Header() {
  const { keycloak } = useKeycloak();
  const isAuthenticated = keycloak.authenticated;

  const { isMobile } = useSelector(state => state.common);
  const { customerInfo } = useSelector(state => state.customerInfo);

  const login = () => {
    GaEvent(GaEvents.navigation, { group: GaAction.Login, action: GaAction.Open });
    doLogin();
  };

  const logout = () => {
    GaEvent(GaEvents.navigation, { group: GaAction.Logout, action: GaAction.Close });
    doLogout();
  };

  return (
    <HeaderStyles>
      {!isMobile ? (
        <HeaderDesktop customerInfo={customerInfo} login={login} logout={logout} isAuthenticated={isAuthenticated} />
      ) : (
        <HeaderMobile login={login} logout={logout} isAuthenticated={isAuthenticated} />
      )}
    </HeaderStyles>
  );
}

export default Header;
