import styled from 'styled-components';

export const FeedbackModalSuccessStyles = styled.div`
  text-align: center;

  .feedback-success-text {
    padding-top: 0.8em;
    margin-bottom: 0;
  }

  .feedback-success-btn {
    padding-bottom: 3.5em;
  }
`;
