import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextAreaStyles } from './TextArea.styles';

function TextArea(props) {
  const { t } = useTranslation();

  const { label, value, onChange, id, disabled, placeholder, rows, maxLength, required, error, showErrorLabel, field } = props;

  return (
    <TextAreaStyles error={error}>
      <label className='textarea__label' htmlFor={id}>
        {label}
        {label && required && <span className='required'> *</span>}
      </label>
      <textarea
        className='textarea__input'
        value={value}
        onChange={event => onChange(event)}
        id={id}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        rows={rows}
        required={required}
        data-testid={id}
      />
      {error && showErrorLabel && <label className='error-text'>{t(`error.${field}`)}</label>}
    </TextAreaStyles>
  );
}

TextArea.defaultProps = {
  required: false,
};

TextArea.propTypes = {
  label: PropType.string,
  value: PropType.string,
  onChange: PropType.func,
  id: PropType.string,
  disabled: PropType.bool,
  placeholder: PropType.string,
  rows: PropType.number,
  maxLength: PropType.number,
  required: PropType.bool,
  error: PropType.bool,
  showErrorLabel: PropType.bool,
  field: PropType.string,
};

export default TextArea;
