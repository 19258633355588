import { createSlice } from '@reduxjs/toolkit';
import { MAP_CENTER, MIN_ZOOM } from '../../Components/Steps/MapStep/MapHelper';

const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapZoom: MIN_ZOOM,
    mapCenterPos: MAP_CENTER,
    forceOpenSuggestions: false,
  },
  reducers: {
    setMapZoom(state, action) {
      const { payload } = action;
      return {
        ...state,
        mapZoom: payload,
      };
    },
    setMapCenterPos(state, action) {
      const { payload } = action;
      return {
        ...state,
        mapCenterPos: payload,
      };
    },
    setForceOpenSuggestions(state, action) {
      const { payload } = action;
      return {
        ...state,
        forceOpenSuggestions: payload,
      };
    },
    resetMap(state) {
      return {
        ...state,
        mapZoom: MIN_ZOOM,
        mapCenterPos: MAP_CENTER,
        forceOpenSuggestions: false,
      };
    },
  },
});

export const { setMapZoom, setMapCenterPos, setForceOpenSuggestions, resetMap } = mapSlice.actions;

export default mapSlice.reducer;
