import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    messagesLoading: false,
    msgPageable: {},
  },
  reducers: {
    getMessages(state) {
      return { ...state, messages: [], messagesLoading: true, msgPageable: {} };
    },
    fetchMoreMessages(state) {
      return { ...state, messagesLoading: true };
    },
    getMessagesSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        messages: state.messages.concat(payload.content),
        msgPageable: { ...payload, content: null },
      };
    },
    endMessagesCall(state) {
      return {
        ...state,
        messagesLoading: false,
      };
    },
  },
});

export const { getMessages, fetchMoreMessages, getMessagesSuccess, endMessagesCall } = messagesSlice.actions;

export default messagesSlice.reducer;
