import React from 'react';

function AppLoading() {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img style={{ maxWidth: '192px' }} src={`${process.env.PUBLIC_URL}/assets/images/icon-512x512.png`} alt='maru_logo' />
    </div>
  );
}

export default AppLoading;
