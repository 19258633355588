import { put } from 'redux-saga/effects';

import { handleError } from '../../Service/HttpResponseHandler';
import { NotificationSend } from '../../Helpers/Enums';
import { setApiErrorMessage } from '../Common/commonSlice';
import { API_ENDPOINTS, postData } from '../../Service/FetchService';
import { setNotificationToken, setRegistrationTokenSuccess } from './notificationSlice';

export function* sendNotificationToken(action) {
  const { payload } = action;
  const { type, notificationToken } = payload;

  try {
    const response = yield postData(`${API_ENDPOINTS.NOTIFICATION_TOKEN}/${type}`, { token: notificationToken });
    if (response.status === 200) {
      if (type === NotificationSend.subscribe) {
        yield put(setNotificationToken(notificationToken));
      } else if (type === NotificationSend.unsubscribe) {
        yield put(setNotificationToken(''));
      }
      yield put(setRegistrationTokenSuccess({ success: true, type: type }));
      const data = yield response.data;
      console.log(data);
    } else {
      yield put(setRegistrationTokenSuccess({ success: false, type: type }));
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setRegistrationTokenSuccess({ success: false, type: type }));
    yield put(setApiErrorMessage(handleError(error)));
  }
}

export function* validateIsTokenRegistered(action) {
  const { payload } = action;
  try {
    const response = yield postData(`${API_ENDPOINTS.NOTIFICATION_TOKEN}/validate`, { token: payload });
    if (response.status === 200) {
      const isRegistered = yield response.data;
      if (isRegistered) {
        yield put(setNotificationToken(payload));
      } else {
        yield put(setNotificationToken(''));
      }
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
}
