import styled from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';
import { mediaCss } from '../../Helpers/Enums';

export const HeaderStyles = styled.div`
  .section--gradient-accent {
    background-image: linear-gradient(-45deg, ${Colors.gradient.purple[1]}, ${Colors.gradient.purple[0]});
  }

  .main-page-link {
    color: inherit;
    text-decoration: none;
    cursor: default;
    font-weight: 400;
    align-items: center;
  }

  e-dropdown .dropdown-container {
    z-index: 9999;
    width: auto;
    position: fixed;
    right: 4em;
    ${mediaCss.mobile} {
      width: 60%;
      right: 1em;
    }
  }

  e-dropdown .dropdown-container > * {
    z-index: 9999;
    display: flex;
    justify-content: center;
  }

  e-dropdown .button-icon * {
    margin-right: 0.1em;
    font-size: 1em;
  }

  .header--container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .arrow--logo {
    display: flex;
    margin-right: 0.1em;
    padding: 0.5em;
    e-icon .icon.icon--default,
    e-icon .icon.icon--sm {
      font-size: 1.5rem;
    }
  }
`;
