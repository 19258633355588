import { createSlice } from '@reduxjs/toolkit';

const messageStatusSlice = createSlice({
  name: 'messageStatus',
  initialState: {
    msgStatus: {},
    msgStatusLoading: false,
  },
  reducers: {
    clearMessageStatus(state) {
      return { ...state, msgStatus: {}, msgStatusLoading: false };
    },
    getMessageStatus(state) {
      return { ...state, msgStatus: {}, msgStatusLoading: true };
    },
    getMessageStatusSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        msgStatus: payload,
      };
    },
    endMessageStatusCall(state) {
      return {
        ...state,
        msgStatusLoading: false,
      };
    },
  },
});

export const { clearMessageStatus, getMessageStatus, getMessageStatusSuccess, endMessageStatusCall } = messageStatusSlice.actions;

export default messageStatusSlice.reducer;
