import React from 'react';

function ModalContent(props) {
  const { center } = props;
  return (
    <div className={`modal--content ${center ? 'text-center' : ''}`} data-testid='modalContents'>
      {props.children}
    </div>
  );
}

export default ModalContent;
