import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

import NavbarLink from './NavbarLink';
import { GaAction } from '../../Helpers/GoogleAnalytics';
import { useSelector } from 'react-redux';
import { ROUTE_PATHS } from '../../Routes/Routes';
import { NavbarStyles } from './Navbar.styles';

function Navbar(props) {
  const { t } = useTranslation();
  const { unreadMessagesCount } = useSelector(state => state.lastReading);

  const { hasLabel } = props;

  return (
    <NavbarStyles>
      <ul className='navbar'>
        <NavbarLink
          path={ROUTE_PATHS.MESSAGES}
          icon={'message-md'}
          label={t('header.navigation.messages')}
          hasLabel={hasLabel}
          gaLabel={GaAction.Messages}
          unreadMessagesCount={unreadMessagesCount}
        />
        <NavbarLink
          path={ROUTE_PATHS.REPORTING}
          icon={'notify-md'}
          label={t('header.navigation.report')}
          hasLabel={hasLabel}
          gaLabel={GaAction.Notify}
        />
        <NavbarLink
          path={ROUTE_PATHS.MAP}
          icon={'location-md'}
          label={t('header.navigation.map')}
          hasLabel={hasLabel}
          gaLabel={GaAction.Map}
        />
        <NavbarLink
          path={ROUTE_PATHS.INFO}
          icon={'dots-horizontal'}
          label={t('header.navigation.info')}
          hasLabel={hasLabel}
          gaLabel={GaAction.More}
        />
      </ul>
    </NavbarStyles>
  );
}

Navbar.propTypes = {
  hasLabel: PropType.bool,
};

export default Navbar;
