import styled from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';
import { mediaCss } from '../../Helpers/Enums';

export const InstallBannerStyles = styled.div`
  #installBannerDiv {
    position: fixed;
    right: 1em;
    padding: 1em;
    bottom: 1em;
    box-shadow: 0px 0px 2px black;
    background-color: ${Colors.background.white};
    min-width: 20em;
    display: none;
    ${mediaCss.mobile} {
      right: 0.5em;
      bottom: 0.5em;
      min-width: 19em;
    }
  }

  .elv-icon {
    -ms-flex-pack: center;
    justify-content: center;
    height: 3em;
    width: 3em;
  }

  .banner-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5em;
  }

  e-button .btn--is-icon {
    padding: 0.5em;
  }

  #closeBtn {
    margin-left: 1em;
    e-button .btn--transparent {
      background-color: ${Colors.background.lightGray};
    }
  }

  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    position: relative;
    text-decoration: none;
    white-space: inherit;
    background-color: #00a8e6;
    border-radius: 290486px;
    color: #fff;
    font-size: 1em;
    font-family: milibus, sans-serif;
    font-weight: 700;
    padding: 1em 2em;
    -webkit-transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: inset 0 0 0 2px #00a8e6;
    background-color: transparent;
    color: #333;
    :hover {
      background-color: #e8f7fd;
    }
  }
`;
