import Keycloak from 'keycloak-js';

import { GaEvent, GaAction, GaEvents } from './Helpers/GoogleAnalytics';
import { ROUTE_PATHS } from './Routes/Routes';

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_URL || window._env_.KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || window._env_.KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || window._env_.KEYCLOAK_CLIENT_ID,
};

const keycloakInitOptions = {
  pkceMethod: 'S256',
};

const keycloak = new Keycloak(keycloakConfig);
let keycloakInitialized = false;

const keycloakEvent = event => {
  if (event === 'onReady') {
    keycloakInitialized = true;
  } else if (event === 'onAuthSuccess') {
    GaEvent(GaEvents.login, { action: GaAction.Success, method: GaAction[getParsedToken().amr] });
  } else if (event === 'onAuthError') {
    GaEvent(GaEvents.login, { action: GaAction.Error, method: GaAction[getParsedToken().amr], error_result: 'error' });
  }
};

const getToken = () => {
  return keycloak ? keycloak.token : undefined;
};

const authenticated = () => {
  return keycloak ? keycloak.authenticated : false;
};

const getParsedToken = () => {
  return getToken() ? keycloak.tokenParsed : undefined;
};

const getLoginName = () => {
  return getParsedToken() ? getParsedToken().name : '';
};

const doLogin = keycloak.login;

const doLogout = () => {
  localStorage.removeItem('persist:root');
  keycloak.logout({ redirectUri: `${window.location.origin}${ROUTE_PATHS.MESSAGES}` });
};

const updateToken = (success, error) => {
  if (!keycloak.authenticated) {
    return success();
  }
  keycloak
    .updateToken(5)
    .then(() => {
      return success();
    })
    .catch(() => {
      return error();
    });
  return null;
};

export {
  keycloak,
  keycloakInitOptions,
  getToken,
  getParsedToken,
  keycloakEvent,
  keycloakInitialized,
  doLogin,
  doLogout,
  updateToken,
  getLoginName,
  authenticated,
};
