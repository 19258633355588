import React from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import ModalHeader from '../ModalHeader';
import ModalContent from '../ModalContent';
import { ROUTE_PATHS } from '../../../Routes/Routes';
import { getLastReading } from '../../../Store/LastReading/lastReadingSlice';
import { GaAction, GaEvent, GaEvents } from '../../../Helpers/GoogleAnalytics';
import { setActiveCustomer, setRoleModalOpen } from '../../../Store/CustomerInfo/customerInfoSlice';
import { getServicePoints, setActiveServicePoint } from '../../../Store/ServicePoints/servicePointsSlice';

function RoleModal() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { roleModalOpen, customerInfo, customerInfoActive } = useSelector(state => state.customerInfo);
  const { isMobile } = useSelector(state => state.common);

  const handleClose = () => {
    dispatch(setRoleModalOpen(false));
    GaEvent(GaEvents.navigation, { group: GaAction.Role, action: GaAction.Close });
  };

  const handleRoleClick = role => {
    dispatch(setActiveCustomer(role));
    dispatch(setActiveServicePoint({}));
    dispatch(setRoleModalOpen(false));
    if (location.pathname === ROUTE_PATHS.OUTAGE_REPORT) {
      navigate(ROUTE_PATHS.INDEX);
    }
    if (location.pathname === ROUTE_PATHS.MESSAGES) {
      dispatch(getServicePoints({ askMessages: true }));
    }
    dispatch(getLastReading());
    GaEvent(GaEvents.navigation, { group: GaAction.Role, action: GaAction.Select });
  };

  const isActive = customer => {
    return (
      customerInfoActive.name === customer.name &&
      (customerInfoActive.customerId === customer.id || customerInfoActive.customerId === customer.relatedCustomerId)
    );
  };

  function ModalContentItem(props) {
    const { item } = props;
    const isRoleSelected = isActive(item);
    return (
      <div
        className={`content--item--container ${isRoleSelected ? 'content--item--active' : ''}`}
        onClick={() => handleRoleClick(item)}
        data-testid='clickRoleSelect'
      >
        <div className='content--item-row'>
          {isRoleSelected && (
            <div className='flex'>
              <e-icon icon='caret-right' size='sm' color='primary' />
            </div>
          )}
          <div>
            <h5>{item.name}</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Dialog open={roleModalOpen} onClose={handleClose} fullScreen={isMobile} fullWidth maxWidth={'sm'}>
      <div className='dialog--container'>
        <ModalHeader headerText={t('header.changeRole')} handleClose={handleClose} showCloseBtn={true} />
        <hr className='dialog--separator' />
        <ModalContent>
          <ModalContentItem item={customerInfo} />
          {customerInfo.roles.map((customer, index) => {
            return <ModalContentItem key={index} item={customer} />;
          })}
        </ModalContent>
      </div>
    </Dialog>
  );
}

export default RoleModal;
