import axios from 'axios';
import { doLogin, getToken, updateToken } from '../keycloak';

import { baseUrl } from '../Helpers/Common';

export const API_ENDPOINTS = {
  SERVICE_POINTS: `${baseUrl}/servicepoints`,
  SERVICE_POINTS_LAST_READING: `${baseUrl}/servicepointevent/latest`,
  CUSTOMER_INFO: `${baseUrl}/customer`,
  MESSAGES: `${baseUrl}/servicepointevent`,
  VERSION: `${baseUrl}/public/version`,
  CLIENT_VERSION: `${process.env.PUBLIC_URL}/client-version`,
  NOTIFY_OUTAGE: `${baseUrl}/notify/outage`,
  NOTIFY_OUTAGE_UPDATE_POWER_IS_BACK: `${baseUrl}/notify/outage/update`,
  NOTIFY_OUTAGE_PUBLIC: `${baseUrl}/public/notify/outage`,
  NOTIFY_GENERAL: `${baseUrl}/notify/general`,
  ADDRESS_GEOCODE: `${baseUrl}/public/geo`,
  ADDRESS_GEOCODE_COORDS: `${baseUrl}/public/geo/reverse`,
  NOTIFICATION_TOKEN: `${baseUrl}/pushnotifications`,
  LANGUAGE_CHANGE: `${baseUrl}/customer/language`,
  FEEDBACK: `${baseUrl}/public/feedback`,
  IMAGE_UPLOAD: `${baseUrl}/notify/outage/image/upload`,
  MESSAGE_STATUS: `${baseUrl}/troublecall/status`,
  DUMMY_INGRESS_ENDPOINT: `${process.env.PUBLIC_URL}/log`,
  ALLOW_NON_AUTHENTICATED_OUTAGE: `${baseUrl}/ua/info/allowNonAuthenticatedOutage`,
};

export function getFetchOptions(token, abortController) {
  const fetchHeaders = {
    cache: 'no-cache',
    credentials: 'include',
    timeout: 1000 * 60,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
  };

  if (token) {
    fetchHeaders.headers.Authorization = token;
  }

  if (abortController) {
    fetchHeaders.signal = abortController.signal;
  }

  return fetchHeaders;
}

async function getAccessToken() {
  return new Promise(resolve => {
    updateToken(
      () => {
        return resolve(getToken() ? `Bearer ${getToken()}` : undefined);
      },
      () => {
        return doLogin();
      }
    );
  });
}

export async function getData(url, abortController) {
  return getAccessToken().then(token => {
    const fetchOptions = getFetchOptions(token, abortController);
    return axios.get(url, fetchOptions);
  });
}

export async function postData(url, data) {
  return getAccessToken().then(token => {
    return axios.post(withClientVersion(url), data, getFetchOptions(token));
  });
}

export async function postFormData(url, data) {
  return getAccessToken().then(token => {
    return axios.postForm(withClientVersion(url), data, getFetchOptions(token));
  });
}

function withClientVersion(url) {
  return `${url}?clientVer=${process.env.REACT_APP_VERSION}`;
}
