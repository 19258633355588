import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FooterDesktopStyles } from './FooterDesktop.styles';
import { setFeedbackModalOpen, setPrivacyModalOpen } from '../../../Store/Common/commonSlice';
import { currentYear } from '../../../Helpers/Common';

function FooterDesktop() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { privacyModalOpen, feedbackModalOpen } = useSelector(state => state.common);

  const openModal = (open, setOpen) => {
    dispatch(setOpen(!open));
  };

  return (
    <FooterDesktopStyles>
      <e-section>
        <div className='grid footer--container'>
          <div className='col-12@sm flex justify-center flex-center'>
            <div className='col-12@sm flex justify-start padding-left-xxl'>
              <div className='flex flex-column'>
                <h6
                  className='text-sm copyright link'
                  onClick={() => openModal(privacyModalOpen, setPrivacyModalOpen)}
                  data-testid={'openPrivacyModal'}
                >{`${t('more.cards.privacy')} »`}</h6>
                <h6 className='text-sm copyright'>&copy; {currentYear + t('copyright')}</h6>
              </div>
            </div>
            <img
              style={{ width: '120px' }}
              className='vertical-bottom'
              src={`${process.env.PUBLIC_URL}/assets/images/icon-512x512.png`}
              alt='maru_logo'
            />
            <div className='col-12@sm flex justify-end padding-right-xxl'>
              <div className='flex flex-column'>
                <h6
                  className='text-sm feedback link'
                  onClick={() => openModal(feedbackModalOpen, setFeedbackModalOpen)}
                  data-testid={'openFeedbackModal'}
                >{`${t('more.cards.app_problem.open_modal')} »`}</h6>
              </div>
            </div>
          </div>
        </div>
      </e-section>
    </FooterDesktopStyles>
  );
}

export default FooterDesktop;
