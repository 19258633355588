import styled from 'styled-components';
import { Colors } from '../../AppMain/AppMain.styles';

export const getBorderColor = params => {
  if (params.error) {
    return Colors.red.primary;
  }
  return Colors.border.gray;
};

export const getErrorVisibility = params => {
  if (params.error) {
    return 'visible';
  }
  return 'hidden';
};

export const getBorderFocusedColor = params => {
  if (params.error) {
    return Colors.red.primary;
  }
  return Colors.blue.primary;
};

export const TextInputStyles = styled.div`
  .textfield__label {
    display: flex;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.2;
    margin: 0 0 0.25em;
  }

  .textfield__input {
    padding: 0.4em 0.5em;
    width: 100%;
    transition: all 0.2s cubic-bezier(0.14, 0.25, 0.54, 1);
    outline: 0;
    border: 1px solid ${props => getBorderColor(props)};
    :focus {
      border-color: ${props => getBorderFocusedColor(props)};
    }
    :disabled {
      color: ${Colors.text.disabled};
      cursor: not-allowed;
      background-color: ${Colors.background.disabled};
      opacity: 0.8;
    }
  }

  .required {
    color: ${Colors.red.primary};
    white-space: pre;
  }

  .error-text {
    font-size: 0.7em;
    font-style: italic;
    color: ${Colors.red.primary};
    visibility: ${props => getErrorVisibility(props)};
    padding-top: 0.2em;
  }
`;
