import { put, select } from 'redux-saga/effects';

import { handleError } from '../../Service/HttpResponseHandler';
import { getMessages } from '../Messages/messagesSlice';
import { setApiErrorMessage } from '../Common/commonSlice';
import { API_ENDPOINTS, getData } from '../../Service/FetchService';
import { getCustomerId, getRelatedCustomerId } from '../CustomerInfo/customerInfoSlice';
import { endServicePointsCall, getServicePointsLength, getServicePointsSuccess, setActiveServicePointOpen } from './servicePointsSlice';

export function* readServicePoints(action) {
  const { payload } = action;
  const { askMessages } = payload;
  const customerId = yield select(getCustomerId);
  const relatedCustomerId = yield select(getRelatedCustomerId);
  if (!customerId && !relatedCustomerId) {
    yield put(endServicePointsCall());
    return;
  }
  try {
    const response = yield getData(`${API_ENDPOINTS.SERVICE_POINTS}${urlParamsForServicePoints(customerId, relatedCustomerId)}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getServicePointsSuccess({ ...data, setActiveSp: askMessages }));
      const servicePointsLength = yield select(getServicePointsLength);
      if (askMessages) {
        if (servicePointsLength > 0) {
          yield put(getMessages({}));
        }
        if (servicePointsLength > 1) {
          yield put(setActiveServicePointOpen(true));
        }
      }
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endServicePointsCall());
}

const urlParamsForServicePoints = (customerId, relatedCustomerId) => {
  if (customerId !== relatedCustomerId && relatedCustomerId) {
    return `?relatedCustomerId=${relatedCustomerId}`;
  } else {
    return '';
  }
};
