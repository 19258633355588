import styled from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';

export const NoticeCompStyles = styled.div`
  div:not(:first-child) {
    border-top: 1px solid ${Colors.border.gray};
  }

  .close-btn {
    border: none !important;
  }
`;
