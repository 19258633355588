import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TextArea from '../../Input/TextArea/TextArea';
import TextInput from '../../Input/TextInput/TextInput';
import ForwardBtn from '../../Button/ForwardBtn/ForwardBtn';
import ModalHeader from '../ModalHeader';
import ModalContent from '../ModalContent';
import NoticeInfoText from '../../Notice/NoticeInfoText';
import FeedbackSuccess from './FeedbackSuccess';
import { getApiVersion } from '../../../Store/AppVersion/appVersionSlice';
import { setFeedbackModalOpen } from '../../../Store/Common/commonSlice';
import { NoticeTypes, ValidationFields } from '../../../Helpers/Enums';
import { sendFeedback, setFeedback, setFeedbackSuccess, setSenderEmail } from '../../../Store/Feedback/feedbackSlice';

function FeedbackModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { feedbackSuccess, feedbackSent, senderEmail, feedback } = useSelector(state => state.feedback);
  const { isMobile, feedbackModalOpen } = useSelector(state => state.common);

  const [feedBackFormError, setFeedBackFormError] = useState({
    feedback: true,
    email: false,
  });
  const [showFormErrors, setShowFormErrors] = useState(false);

  useEffect(() => {
    dispatch(setFeedbackSuccess({ success: false, sent: false }));
    dispatch(getApiVersion());
  }, []);

  const handleClose = () => {
    dispatch(setFeedbackSuccess({ success: false, sent: false }));
    dispatch(setFeedbackModalOpen(false));
  };

  const handleSendFeedback = () => {
    if (feedBackFormError.feedback || feedBackFormError.email) {
      setShowFormErrors(true);
      return;
    }
    dispatch(sendFeedback());
  };

  const handleFeedbackChanged = event => {
    if (event.target && event.target.validity) {
      setFeedBackFormError({ ...feedBackFormError, feedback: !event.target.validity.valid });
    }
    dispatch(setFeedback(event.target.value));
  };

  const handleEmailChange = event => {
    setFeedBackFormError({ ...feedBackFormError, email: !!(event.target.value && !validator.isEmail(event.target.value)) });
    dispatch(setSenderEmail(event.target.value));
  };

  return (
    <Dialog open={feedbackModalOpen} onClose={handleClose} fullScreen={isMobile} fullWidth maxWidth={'md'}>
      <div className='dialog--container'>
        <ModalHeader
          headerText={t('more.cards.app_problem.title')}
          handleClose={handleClose}
          hideHeaderLabel={feedbackSent && feedbackSuccess}
          showCloseBtn={true}
        />
        {!feedbackSent && <hr className='dialog--separator' />}
        {feedbackSent && !feedbackSuccess && (
          <div className='feedback-error-texts'>
            <NoticeInfoText type={NoticeTypes.feedbackError} label={t('error.overall_error')} />
          </div>
        )}
        {!feedbackSent || !feedbackSuccess ? (
          <ModalContent>
            <TextArea
              value={feedback}
              field={ValidationFields.feedback}
              onChange={event => handleFeedbackChanged(event)}
              placeholder={t('more.cards.app_problem.placeholder')}
              rows={6}
              id={'feedbackTextarea'}
              error={showFormErrors && feedBackFormError.feedback}
              showErrorLabel={true}
              required={true}
            />
            <div className='modal-content-email-container grid'>
              <span className='modal-content-email-desc'>{t('more.modal.email_optional')}</span>
              <div className='col-12@sm col-6@md modal-content-email-input'>
                <TextInput
                  value={senderEmail}
                  id={'emailAddress'}
                  placeholder={t('more.modal.email_address')}
                  maxLength={200}
                  onChange={event => handleEmailChange(event)}
                  field={ValidationFields.email}
                  error={showFormErrors && feedBackFormError.email}
                />
              </div>
            </div>
            <ForwardBtn label={t('more.cards.app_problem.send')} onClick={() => handleSendFeedback()} />
          </ModalContent>
        ) : (
          <FeedbackSuccess onClick={() => handleClose()} />
        )}
      </div>
    </Dialog>
  );
}

export default FeedbackModal;
