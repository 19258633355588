import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: window._env_.FIREBASE_API_KEY,
  authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
  projectId: window._env_.FIREBASE_PROJECT_ID,
  storageBucket: window._env_.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: window._env_.FIREBASE_MESSAGING_SENDER_ID,
  appId: window._env_.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
let messaging;

isSupported()
  .then(supported => {
    if (supported) {
      messaging = getMessaging(app);
    }
  })
  .catch(err => {
    console.log(err);
    return null;
  });

export const getFirebaseToken = () => {
  return isSupported()
    .then(supported => {
      if (supported) {
        return getToken(messaging, { vapidKey: window._env_.FIREBASE_VAPID_KEY });
      } else {
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      return null;
    });
};
