import styled from 'styled-components';

export const HeaderDesktopStyles = styled.div`
  .logo-row {
    padding: 0.5em 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    e-icon .icon.icon--default,
    e-icon .icon.icon--sm {
      font-size: 2rem;
    }
  }

  e-header-block .header__block {
    padding: 0;
  }

  .header-text-container .content-wrapper {
    padding: 1em 3em;
  }

  .text-row {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;
