import React from 'react';
import PropType from 'prop-types';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ForwardBtn from '../../Button/ForwardBtn/ForwardBtn';
import ModalHeader from '../ModalHeader';
import ModalContent from '../ModalContent';
import { GaAction, GaEvent, GaEvents } from '../../../Helpers/GoogleAnalytics';

function VersionUpdaterModal(props) {
  const { t } = useTranslation();

  const { open } = props;

  const { isMobile } = useSelector(state => state.common);

  const handleRefreshPage = () => {
    GaEvent(GaEvents.pageMore, { group: GaAction.UpdateClientVersion, action: GaAction.UpdateClientVersion });
    window.location.reload();
  };

  return (
    <Dialog open={open} fullScreen={isMobile} fullWidth maxWidth={'sm'}>
      <div className='dialog--container'>
        <ModalHeader headerText={t('update.update_header')} showCloseBtn={false} center />
        <hr className='dialog--separator' />
        <ModalContent center>
          <span>{t('update.update_desc')}</span>
          <ForwardBtn label={t('update.update_btn')} onClick={() => handleRefreshPage()} />
        </ModalContent>
      </div>
    </Dialog>
  );
}

VersionUpdaterModal.propTypes = {
  open: PropType.bool,
};

export default VersionUpdaterModal;
