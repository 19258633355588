import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isMobile: false,
    accordionOpen: { suggestions: false },
    privacyModalOpen: false,
    feedbackModalOpen: false,
    apiErrorMessage: '',
  },
  reducers: {
    setIsMobile(state, action) {
      const { payload } = action;
      return {
        ...state,
        isMobile: payload,
      };
    },
    setPrivacyModalOpen(state, action) {
      const { payload } = action;
      return {
        ...state,
        privacyModalOpen: payload,
      };
    },
    setFeedbackModalOpen(state, action) {
      const { payload } = action;
      return {
        ...state,
        feedbackModalOpen: payload,
      };
    },
    setAccordionOpen(state, action) {
      const { payload } = action;
      return {
        ...state,
        accordionOpen: payload,
      };
    },
    setApiErrorMessage(state, action) {
      const { payload } = action;
      return {
        ...state,
        apiErrorMessage: payload,
      };
    },
  },
});

export const { setIsMobile, setAccordionOpen, setApiErrorMessage, setPrivacyModalOpen, setFeedbackModalOpen } = commonSlice.actions;

export default commonSlice.reducer;
