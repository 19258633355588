import { createSlice } from '@reduxjs/toolkit';

const commonNotifySlice = createSlice({
  name: 'commonNotify',
  initialState: {
    activeStep: '',
    dangerType: '',
    descriptionField: '',
    isClosedArea: '',
    personData: {
      name: '',
      phoneNumber: '',
      email: '',
    },
    locationData: {},
  },
  reducers: {
    clearCommonOutageSteps(state) {
      return {
        ...state,
        activeStep: '',
        dangerType: '',
        descriptionField: '',
        isClosedArea: '',
        personData: {
          name: '',
          phoneNumber: '',
          email: '',
        },
      };
    },
    setActiveStep(state, action) {
      const { payload } = action;
      return {
        ...state,
        activeStep: payload,
      };
    },
    setDangerType(state, action) {
      const { payload } = action;
      return {
        ...state,
        dangerType: payload,
      };
    },
    setPersonData(state, action) {
      const { payload } = action;
      return {
        ...state,
        personData: payload,
      };
    },
    setDescriptionField(state, action) {
      const { payload } = action;
      return {
        ...state,
        descriptionField: payload,
      };
    },
    setIsClosedArea(state, action) {
      const { payload } = action;
      return {
        ...state,
        isClosedArea: payload,
      };
    },
  },
});

export const getPhoneNumber = state => {
  return { phoneNumber: state.commonNotify.personData.phoneNumber };
};

export const getPersonName = state => {
  return { personName: state.commonNotify.personData.name };
};

export const getEmail = state => {
  return { email: state.commonNotify.personData.email };
};

export const getDescriptionField = state => {
  return { descriptionField: state.commonNotify.descriptionField };
};

export const getDangerType = state => {
  return { type: state.commonNotify.dangerType };
};

export const { clearCommonOutageSteps, setActiveStep, setPersonData, setDescriptionField, setIsClosedArea, setDangerType } =
  commonNotifySlice.actions;

export default commonNotifySlice.reducer;
