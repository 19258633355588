import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextInputStyles } from './TextInput.styles';

function TextInput(props) {
  const { t } = useTranslation();

  const { type, label, value, onChange, onFocus, id, disabled, placeholder, pattern, required, maxLength, error, field } = props;

  return (
    <TextInputStyles error={error}>
      <label className='textfield__label' htmlFor={id}>
        {label}
        {required && <span className='required'> *</span>}
      </label>
      <input
        type={type}
        id={id}
        className='textfield__input '
        value={value}
        onChange={event => onChange(event)}
        onFocus={event => (onFocus ? onFocus(event) : {})}
        disabled={disabled}
        placeholder={placeholder}
        pattern={pattern}
        required={required}
        maxLength={maxLength}
        data-testid={id}
      />
      {error && <label className='error-text'>{t(`error.${field}`)}</label>}
    </TextInputStyles>
  );
}

TextInput.defaultProps = {
  type: 'text',
  required: false,
};

TextInput.propTypes = {
  type: PropType.string,
  label: PropType.oneOfType([PropType.string, PropType.object]),
  value: PropType.string,
  onChange: PropType.func,
  onFocus: PropType.func,
  id: PropType.string,
  disabled: PropType.bool,
  required: PropType.bool,
  maxLength: PropType.number,
  error: PropType.bool,
  field: PropType.string,
};

export default TextInput;
