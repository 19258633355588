import { put, select } from 'redux-saga/effects';

import { getLoginName } from '../../keycloak';
import { getVersionFromState } from '../AppVersion/appVersionSlice';
import { API_ENDPOINTS, postData } from '../../Service/FetchService';
import { browserName, browserVersion } from 'react-device-detect';
import { getFeedback, getUserEmail, setFeedbackSuccess } from './feedbackSlice';

export function* sendFeedbackCall() {
  const feedback = yield select(getFeedback);
  const apiVersion = yield select(getVersionFromState);
  const userEmail = yield select(getUserEmail);
  const body = {
    description: feedback,
    browser: `${browserName} ${browserVersion}`,
    apiVersion: apiVersion,
    clientVersion: process.env.REACT_APP_VERSION,
    name: getLoginName(),
    email: userEmail,
  };
  try {
    const response = yield postData(`${API_ENDPOINTS.FEEDBACK}`, body);
    if (response.status === 200) {
      yield put(setFeedbackSuccess({ success: true, sent: true }));
    } else {
      yield put(setFeedbackSuccess({ success: false, sent: true }));
    }
  } catch (error) {
    yield put(setFeedbackSuccess({ success: false, sent: true }));
  }
}
