import React from 'react';
import PropType from 'prop-types';

function ModalHeader(props) {
  const { headerText, handleClose, hideHeaderLabel, showCloseBtn, center } = props;
  return (
    <div className={`modal--header ${center ? 'modal--header--center' : 'modal--header--between'}`}>
      <h3>{!hideHeaderLabel && headerText}</h3>
      {showCloseBtn && (
        <e-button color='gray' icon-button onClick={handleClose} data-testid='closeModalIcon'>
          <e-icon icon='close' />
        </e-button>
      )}
    </div>
  );
}

ModalHeader.propTypes = {
  headerText: PropType.string,
  handleClose: PropType.func,
  hideHeaderLabel: PropType.bool,
  showCloseBtn: PropType.bool,
  center: PropType.bool,
};

export default ModalHeader;
