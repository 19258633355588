import { all, takeEvery, throttle } from 'redux-saga/effects';

import { sendLogger } from './Logger/saga';
import { sendFeedbackCall } from './Feedback/saga';
import { readLastReadings } from './LastReading/saga';
import { readServicePoints } from './ServicePoints/saga';
import { sendImagesToServer } from './ImageUpload/saga';
import { readAddressesFromApi } from './AddressApi/saga';
import { changeLanguage, readCustomerInfo } from './CustomerInfo/saga';
import { readApiVersion, readClientVersion, readFeatures } from './AppVersion/saga';
import { sendNotifyOutage, updateOutageUpdatePowerIsBack } from './Notify/saga';
import { sendNotificationToken, validateIsTokenRegistered } from './Notification/saga';
import { readMessageInfo, readMessagesForSp, readMessageStatus } from './Messages/saga';

import { getLogger } from './Logger/loggerSlice';
import { addressApi } from './AddressApi/addressApiSlice';
import { notifyFetch } from './Notify/notifyFetchSlice';
import { sendFeedback } from './Feedback/feedbackSlice';
import { uploadImages } from './ImageUpload/imageUploadSlice';
import { getMessageInfo } from './Messages/messageInfoSlice';
import { getLastReading } from './LastReading/lastReadingSlice';
import { getMessageStatus } from './Messages/messagesStatusSlice';
import { getServicePoints } from './ServicePoints/servicePointsSlice';
import { sendOutageUpdatePowerIsBack } from './Notify/outageUpdatePowerIsBackSlice';
import { fetchMoreMessages, getMessages } from './Messages/messagesSlice';
import { getApiVersion, getClientVersion, getFeatures } from './AppVersion/appVersionSlice';
import { getCustomerInfo, setCustomerLanguage } from './CustomerInfo/customerInfoSlice';
import { subscribeOrUnsubscribeToNotification, validateRegistration } from './Notification/notificationSlice';

const Logger = [takeEvery(getLogger.type, sendLogger)];
const Notify = [takeEvery(notifyFetch.type, sendNotifyOutage)];
const Feedback = [takeEvery(sendFeedback.type, sendFeedbackCall)];
const Messages = [throttle(2000, getMessages.type, readMessagesForSp), throttle(2000, fetchMoreMessages.type, readMessagesForSp)];
const ApiVersion = [
  takeEvery(getApiVersion.type, readApiVersion),
  takeEvery(getClientVersion.type, readClientVersion),
  takeEvery(getFeatures.type, readFeatures),
];
const AddressApi = [throttle(1000, addressApi.type, readAddressesFromApi)];
const ImageUpload = [takeEvery(uploadImages.type, sendImagesToServer)];
const MessageInfo = [throttle(2000, getMessageInfo().type, readMessageInfo)];
const CustomerInfo = [takeEvery(getCustomerInfo.type, readCustomerInfo), takeEvery(setCustomerLanguage.type, changeLanguage)];
const Notification = [
  takeEvery(subscribeOrUnsubscribeToNotification.type, sendNotificationToken),
  takeEvery(validateRegistration.type, validateIsTokenRegistered),
];
const LastReading = [throttle(2000, getLastReading.type, readLastReadings)];
const ServicePoints = [throttle(2000, getServicePoints.type, readServicePoints)];
const MessageStatus = [throttle(2000, getMessageStatus.type, readMessageStatus)];
const OutageUpdatePowerIsBack = [takeEvery(sendOutageUpdatePowerIsBack.type, updateOutageUpdatePowerIsBack)];

export default function* Saga() {
  yield all([...Logger]);
  yield all([...Notify]);
  yield all([...Messages]);
  yield all([...Feedback]);
  yield all([...ApiVersion]);
  yield all([...AddressApi]);
  yield all([...MessageInfo]);
  yield all([...ImageUpload]);
  yield all([...LastReading]);
  yield all([...CustomerInfo]);
  yield all([...Notification]);
  yield all([...ServicePoints]);
  yield all([...MessageStatus]);
  yield all([...OutageUpdatePowerIsBack]);
}
