import styled from 'styled-components';

import { Colors } from '../../AppMain/AppMain.styles';

export const FooterDesktopStyles = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 135px;
  background-color: ${Colors.background.white};

  .copyright {
    color: ${Colors.text.additionalDark};
    font-weight: 100;
  }

  .feedback {
    color: ${Colors.text.additionalDark};
    font-weight: 100;
    margin-bottom: 2.5em;
  }

  .custom-icon {
    -ms-flex-pack: center;
    justify-content: center;
    fill: currentColor;
    color: inherit;
    height: 0.75em;
    width: 0.75em;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    pointer-events: none;
    max-width: none;
    font-size: 2rem;
  }
`;
