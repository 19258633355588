import { createSlice } from '@reduxjs/toolkit';

const loggerSlice = createSlice({
  name: 'logger',
  initialState: {
    logger: '',
  },
  reducers: {
    getLogger(state) {
      return {
        ...state,
        logger: '',
      };
    },
  },
});

export const { getLogger } = loggerSlice.actions;

export default loggerSlice.reducer;
