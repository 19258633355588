import { createSlice } from '@reduxjs/toolkit';

const outageUpdatePowerIsBackSlice = createSlice({
  name: 'outageUpdatePowerIsBack',
  initialState: {
    outageUpdatePowerIsBackLoading: false,
  },
  reducers: {
    sendOutageUpdatePowerIsBack(state) {
      return {
        ...state,
        outageUpdatePowerIsBackLoading: true,
      };
    },
    endOutageUpdatePowerIsBack(state) {
      return {
        ...state,
        outageUpdatePowerIsBackLoading: false,
      };
    },
  },
});

export const { sendOutageUpdatePowerIsBack, endOutageUpdatePowerIsBack } = outageUpdatePowerIsBackSlice.actions;

export default outageUpdatePowerIsBackSlice.reducer;
