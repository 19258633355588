import 'proj4leaflet';
import proj4 from 'proj4';
import L from 'leaflet';

export const ATTRIBUTION = "<a href='http://www.maaamet.ee/'>Maa-amet</a>";
export const TILE_URL = 'https://tiles.maaamet.ee/tm/tms/1.0.0/kaart/{z}/{x}/{y}.png&ASUTUS=ELV&KESKKOND=LIVE&IS=OutageMap';
export const MAX_ZOOM = 13;
export const MIN_ZOOM = 3;
export const MAP_CENTER = [58.611152, 25.367983];
export const EPSG3301 = 'EPSG:3301';
export const WGS84 = 'WGS84';
export const resolutions = [
  4000, 2000, 1000, 500, 250, 125, 62.5, 31.25, 15.625, 7.8125, 3.90625, 1.953125, 0.9765625, 0.48828125, 0.244140625, 0.122070313,
  0.061035156, 0.030517578, 0.015258789,
];

export const CRS3301 = new L.Proj.CRS(
  'EPSG:3301',
  '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
  {
    resolutions,
    origin: [40500, 5993000],
    bounds: L.bounds([40500, 5993000], [1064500, 7017000]),
  }
);

export const WGS84toEPSG3301 = (lat, lng) => {
  if (!lat || !lng) {
    return { x: 0, y: 0 };
  }
  const converted = proj4(WGS84, EPSG3301, [lng, lat]);
  return { x: converted[0], y: converted[1] };
};

export const getMapHeight = (isMobile, hasError) => {
  const headerHeight = (isMobile ? 68 : 215) + 45 + getErrorMsgHeight(isMobile, hasError);
  return {
    width: window.innerWidth,
    height: window.innerHeight - headerHeight,
  };
};

export const getErrorMsgHeight = (isMobile, hasError) => {
  if (hasError) {
    return isMobile ? 82 : 58;
  } else {
    return 0;
  }
};

export const mapMarker = L.icon({
  iconUrl: `${process.env.PUBLIC_URL}/assets/images/pin.png`,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
});
