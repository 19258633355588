import styled from 'styled-components';
import { mediaCss } from '../../Helpers/Enums';

export const Colors = {
  gradient: {
    primary: ['#00aae7', '#0f7fff'],
    primaryLight: ['#00aae7', '#4cc1fe'],
    purple: ['#00aae7', '#dec2ff'],
  },
  blue: {
    primary: '#00aae7',
    hover: '#0f7fff',
  },
  green: {
    primary: '#44c76b',
    bg: '#e5ffed',
  },
  yellow: {
    primary: '#f5d258',
    bg: '#fff9e5',
  },
  red: {
    primary: '#da3248',
    bg: '#fff0f2',
  },
  background: {
    primary: '#00aae7',
    lightBlue: '#f5fcff',
    bg: '#e8f7fd',
    lightGray: '#f5fafa',
    gray: '#f2f2f2',
    white: '#FFFFFF',
    disabled: '#eff1f4',
  },
  gray: {
    dark: '#adadad',
  },
  text: {
    regular: '#313135',
    additionalDark: '#7b858d',
    additionalLight: '#b7c0c7',
    disabled: '#364454',
  },
  border: {
    gray: '#d1d1d1',
  },
  switch: {
    grayLight: '#e2e6e9',
    grayDark: '#b7c0c7',
    blueLight: '#9cd3fa',
    blueDark: '#0892f4',
  },
  black: {
    black: '#000000',
  },
};

const getBottomPadding = (params, type) => {
  if (params.hasPadding && type === 'desktop') {
    return '135px';
  } else if (params.hasPadding && type === 'mobile') {
    return '61px';
  } else {
    return '0';
  }
};

export const AppMainStyles = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${Colors.background.lightBlue};
  overflow: hidden;
  width: 100%;
  .pointer {
    cursor: pointer;
  }

  #content-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: ${props => getBottomPadding(props, 'desktop')};
  }

  .gap1 {
    row-gap: 1em;
    column-gap: 1em;
  }

  ${mediaCss.mobile} {
    #content-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: ${props => getBottomPadding(props, 'mobile')};
    }
  }

  .error-text {
    color: ${Colors.red.primary};
    font-size: 0.7em;
    font-style: italic;
    padding-top: 0.2em;
  }

  .text-sm {
    font-size: 0.9375rem;
  }
`;
