import { createSlice } from '@reduxjs/toolkit';

const messageInfoSlice = createSlice({
  name: 'messageInfo',
  initialState: {
    messageInfo: {},
    messageInfoLoading: false,
  },
  reducers: {
    getMessageInfo(state) {
      return { ...state, messageInfoLoading: true };
    },
    getMessageInfoSuccess(state, action) {
      const { payload } = action;
      return {
        ...state,
        messageInfo: { ...state.messageInfo, ...payload },
      };
    },
    endMessageInfoCall(state) {
      return {
        ...state,
        messageInfoLoading: false,
      };
    },
  },
});

export const { getMessageInfo, getMessageInfoSuccess, endMessageInfoCall } = messageInfoSlice.actions;

export default messageInfoSlice.reducer;
