import { put, select } from 'redux-saga/effects';

import { API_ENDPOINTS, postData, postFormData } from '../../Service/FetchService';
import {
  clearImages,
  getUploadedImages,
  setImagesUploading,
  uploadImagesSuccess,
  uploadImagesFailure,
  setSendWhileLoadingErr,
} from './imageUploadSlice';

export function* sendImagesToServer(action) {
  const { payload } = action;
  const filesLength = payload.length;
  if (filesLength === 0) {
    yield put(clearImages());
    return;
  }
  let index = 0;
  while (index < filesLength) {
    const uploadedImages = yield select(getUploadedImages);
    const formData = new FormData();
    const file = { ...payload[index] };
    if (isFileAlreadyExisting(file, uploadedImages)) {
      index++;
      continue;
    }
    formData.append('file', new File([file.resized], file.file.name, { type: 'image/jpeg' }));
    try {
      const response = yield postFormData(`${API_ENDPOINTS.IMAGE_UPLOAD}`, formData);
      if (response.status === 200) {
        file.uuid = yield response.data;
        yield put(uploadImagesSuccess(file));
      } else {
        yield put(uploadImagesFailure());
      }
    } catch (error) {
      yield put(uploadImagesFailure());
    }
    index++;
  }
  yield put(setImagesUploading(false));
  yield put(setSendWhileLoadingErr(false));
}

const isFileAlreadyExisting = (file, list) => {
  return list.some(item => item.data_url === file.data_url);
};
