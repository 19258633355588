import { authenticated } from '../keycloak';

export const GaEvent = (category, params) => {
  window.dataLayer.push({
    event: category,
    logged_in: authenticated(),
    ...params,
  });
};

let timeout;
export const GaEventTimeout = (category, params) => {
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    GaEvent(category, params);
  }, 400);
};

export const GaEvents = {
  navigation: 'navigation',
  login: 'login',
  pageMessages: 'page_messages',
  pageNotify: 'page_notify',
  pageMore: 'page_more',
};

export const GaAction = {
  Header: 'Header',
  Logo: 'Logo',
  Language: 'Language',
  Success: 'Success',
  Error: 'Error',
  Login: 'Login',
  Logout: 'Logout',
  Open: 'Open',
  Close: 'Close',
  Menu: 'Menu',
  Refresh: 'Refresh',
  Messages: 'Messages',
  Notify: 'Notify',
  Map: 'Map',
  Weather: 'Weather',
  More: 'More',
  PWA: 'PWA',
  addToScreen: 'Add to screen',
  InfoBlock: 'Info Blocks',
  ElectricityOutage: 'Electricity outage',
  DangerousSituation: 'Dangerous situation',
  DangerRiskSituation: 'Danger risk situation',
  SelectDangerType: 'Select danger type',
  Role: 'Role',
  Select: 'Select',
  servicePointSelect: 'Consumption point selected',
  idcard: 'ID-card',
  smartid: 'Smart-ID',
  mID: 'Mobile-ID',
  Previous: 'Previous',
  AdditionalInformation: 'Additional information',
  NotificationActivationHelp: 'How to activate notifications',
  ToDoBeforeStorm: 'To-do before storm',
  Settings: 'Settings',
  LanguageSelectedEST: 'Language Selected - EST',
  LanguageSelectedENG: 'Language Selected - ENG',
  LanguageSelectedRUS: 'Language Selected - RUS',
  SaveLanguage: 'Save language',
  PrivacyPolicy: 'Privacy Policy',
  Feedback: 'App Feedback',
  Location: 'Location',
  SpSelected: 'Consumption point selected',
  SpSelectedType: 'Consumption point type',
  OtherAddressSelected: 'Other address selected',
  Next: 'Next',
  AddressSearchApi: 'Consumption point address',
  AddressSelectedNext: 'Other address - next',
  OutageInfo: 'Outage Info',
  OutageDanger: 'Outage/Danger',
  form: {
    address: 'Consumption point address',
    phone: 'Contact phone',
    personName: 'Contact name',
    description: 'Additional information',
    email: 'E-mail',
  },
  Send: 'Send',
  MoveStepBack: 'Move step back',
  GoToMap: 'Location Selection',
  MapLocationAdded: 'Location added',
  NoOutageSent: 'No Outage send',
  Search: 'Search',
  FilterModal: 'Filter modal',
  UpdateClientVersion: 'Update Client Version',
};
