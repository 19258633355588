import { createSlice } from '@reduxjs/toolkit';
import { hasServicePoint } from '../../Helpers/HelperMethods';

const servicePointsSlice = createSlice({
  name: 'servicePoints',
  initialState: {
    servicePoints: [],
    servicePointsLoading: false,
    activeServicePoint: {},
    activeServicePointOpen: false,
    preSelectedEic: null,
  },
  reducers: {
    getServicePoints(state) {
      return {
        ...state,
        servicePoints: [],
        servicePointsLoading: true,
        activeServicePointOpen: false,
      };
    },
    getServicePointsSuccess(state, action) {
      const { payload } = action;
      const { servicePoints, setActiveSp } = payload;
      return {
        ...state,
        servicePoints: servicePoints,
        activeServicePoint: !!(setActiveSp && servicePoints && servicePoints.length > 0) ? servicePoints[0] : state.activeServicePoint,
        servicePointsLoading: false,
      };
    },
    setActiveServicePoint(state, action) {
      const { payload } = action;
      return {
        ...state,
        preSelectedEic: null,
        activeServicePoint: payload,
        activeServicePointOpen: hasServicePoint(payload),
      };
    },
    setActiveServicePointOpen(state, action) {
      const { payload } = action;
      return {
        ...state,
        activeServicePointOpen: payload,
      };
    },
    endServicePointsCall(state) {
      return {
        ...state,
        servicePointsLoading: false,
      };
    },
    preSelectEic(state, action) {
      const { payload } = action;
      return {
        ...state,
        preSelectedEic: payload.eic,
      };
    },
  },
});

export const getServicePointsLength = state => {
  return state.servicePoints.servicePoints.length;
};

export const getActiveServicePointEic = state => {
  return state.servicePoints.activeServicePoint.meterEic;
};

export const {
  preSelectEic,
  getServicePoints,
  endServicePointsCall,
  setActiveServicePoint,
  getServicePointsSuccess,
  setActiveServicePointOpen,
} = servicePointsSlice.actions;

export default servicePointsSlice.reducer;
