import { put, select } from 'redux-saga/effects';

import { handleError } from '../../Service/HttpResponseHandler';
import { setApiErrorMessage } from '../Common/commonSlice';
import { API_ENDPOINTS, getData } from '../../Service/FetchService';
import { getCustomerId, getRelatedCustomerId } from '../CustomerInfo/customerInfoSlice';
import { clearLastReading, endLastReadingCall, lastReadingSuccess } from './lastReadingSlice';

export function* readLastReadings() {
  const customerId = yield select(getCustomerId);
  if (!customerId) {
    return;
  }
  const relatedCustomerId = yield select(getRelatedCustomerId);
  const relatedCustomerIdUrl = relatedCustomerId ? `?relatedCustomerId=${relatedCustomerId}` : '';
  yield put(clearLastReading());
  try {
    const response = yield getData(`${API_ENDPOINTS.SERVICE_POINTS_LAST_READING}${relatedCustomerIdUrl}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(lastReadingSuccess(data));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endLastReadingCall());
}
