import { put, select } from 'redux-saga/effects';

import { handleError } from '../../Service/HttpResponseHandler';
import { setApiErrorMessage } from '../Common/commonSlice';
import { getRelatedCustomerId } from '../CustomerInfo/customerInfoSlice';
import { API_ENDPOINTS, getData } from '../../Service/FetchService';
import { getActiveServicePointEic } from '../ServicePoints/servicePointsSlice';
import { endMessagesCall, getMessagesSuccess } from './messagesSlice';
import { endMessageInfoCall, getMessageInfoSuccess } from './messageInfoSlice';
import { clearMessageStatus, endMessageStatusCall, getMessageStatusSuccess } from './messagesStatusSlice';

let abortController;
export function* readMessagesForSp(action) {
  if (abortController) {
    abortController.abort();
  }
  const { payload } = action;
  const { page, size } = payload;
  const eic = yield select(getActiveServicePointEic);
  const pageAndSizeUrl = `?page=${page ? page : 0}&size=${size ? size : '15'}`;
  const customerId = yield select(getRelatedCustomerId);
  const customerIdUrl = customerId ? `&relatedCustomerId=${customerId}` : '';
  yield put(clearMessageStatus());
  if (!eic) {
    yield put(setApiErrorMessage(handleError()));
    yield put(endMessagesCall());
    return;
  }
  try {
    abortController = new AbortController();
    const response = yield getData(`${API_ENDPOINTS.MESSAGES}/${eic}${pageAndSizeUrl}${customerIdUrl}`, abortController);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getMessagesSuccess(data));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
      yield put(getMessagesSuccess(emptyMessagesResponse));
    }
  } catch (error) {
    if (error && error.message === 'canceled') {
      return;
    }
    yield put(setApiErrorMessage(handleError(error)));
    yield put(getMessagesSuccess(emptyMessagesResponse));
  }
  yield put(endMessagesCall());
}

export function* readMessageInfo(action) {
  const { payload } = action;
  const { outageId, discriminator } = payload;
  const eic = yield select(getActiveServicePointEic);
  const relatedCustomer = yield select(getRelatedCustomerId);
  const relatedCustomerUrl = relatedCustomer ? `&relatedCustomerId=${relatedCustomer}` : '';
  if (!outageId || !eic || !discriminator) {
    yield put(endMessageInfoCall(handleError()));
    return;
  }
  try {
    const response = yield getData(`${API_ENDPOINTS.MESSAGES}/${eic}/${outageId}?discriminator=${discriminator}${relatedCustomerUrl}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getMessageInfoSuccess({ [`${outageId} - ${discriminator}`]: data }));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endMessageInfoCall());
}

export function* readMessageStatus(action) {
  const { payload } = action;
  const { eventId } = payload;
  const eic = yield select(getActiveServicePointEic);
  const relatedCustomer = yield select(getRelatedCustomerId);
  const relatedCustomerUrl = relatedCustomer ? `&relatedCustomerId=${relatedCustomer}` : '';
  if (!eventId || !eic) {
    yield put(endMessageStatusCall(handleError()));
    return;
  }
  try {
    const response = yield getData(`${API_ENDPOINTS.MESSAGE_STATUS}?eic=${eic}&eventId=${eventId}${relatedCustomerUrl}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getMessageStatusSuccess(data));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endMessageStatusCall());
}

export const emptyMessagesResponse = {
  content: [],
  pageable: {
    sort: {
      sorted: false,
      unsorted: true,
      empty: true,
    },
    pageSize: 20,
    pageNumber: 0,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalPages: 0,
  totalElements: 0,
  last: true,
  number: 0,
  size: 20,
  numberOfElements: 0,
  sort: {
    sorted: false,
    unsorted: true,
    empty: true,
  },
  first: true,
  empty: true,
};
