import React from 'react';
import PropType from 'prop-types';

import { NoticeTypes } from '../../Helpers/Enums';
import { NoticeStyles } from './Notice.styles';

function NoticeInfoText(props) {
  const { type, label } = props;

  const getIconName = () => {
    if (type === NoticeTypes.warning) {
      return 'attention-mark';
    } else {
      return 'info-fill';
    }
  };

  return (
    <NoticeStyles type={type}>
      <e-icon icon={getIconName()} />
      <span className='notify__message'>{label}</span>
    </NoticeStyles>
  );
}

NoticeInfoText.propTypes = {
  type: PropType.string,
  label: PropType.oneOfType([PropType.string, PropType.object]),
};

export default NoticeInfoText;
