import { put } from 'redux-saga/effects';

import { getApiVersionSuccess, getClientVersionSuccess, getFeaturesSuccess } from './appVersionSlice';
import { API_ENDPOINTS, getData } from '../../Service/FetchService';

export function* readApiVersion() {
  try {
    const response = yield getData(`${API_ENDPOINTS.VERSION}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getApiVersionSuccess(data));
    } else {
      yield put(getApiVersionSuccess('-'));
    }
  } catch (error) {
    yield put(getApiVersionSuccess('-'));
  }
}

export function* readClientVersion() {
  try {
    const response = yield getData(`${API_ENDPOINTS.CLIENT_VERSION}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getClientVersionSuccess(data.version));
    } else {
      yield put(getClientVersionSuccess('-'));
    }
  } catch (error) {
    yield put(getClientVersionSuccess('-'));
  }
}

export function* readFeatures() {
  try {
    const response = yield getData(`${API_ENDPOINTS.ALLOW_NON_AUTHENTICATED_OUTAGE}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getFeaturesSuccess(data));
    } else {
      yield put(getFeaturesSuccess(false));
    }
  } catch (error) {
    yield put(getFeaturesSuccess(false));
  }
}
