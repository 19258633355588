import { ServicePointType } from './Enums';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const isDebug = process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development';

export const baseUrl = process.env.REACT_APP_STORM_API_URL || window._env_.STORM_API_URL;

export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateFormat = 'DD.MM';
export const timeFormat = 'HH:mm';
export const dateYearFormat = 'DD.MM.YYYY';

export const timeIntervalMinutes = 1000 * 60 * 5; // millis * seconds * minutes

export const currentYear = new Date().getFullYear();

export const showOngoingOutagesHours = 5 * 24;
export const showOngoingOutagesHoursPlannedInfo = 24;
export const showEndBtnTimeHours = 2;

export const intervalMessageMinutes = 60 * 6;

export const minutesSinceLastEndMsg = 60 * 2;

export const ServicePointTypeArray = [
  { value: ServicePointType.APARTMENT, text: 'messages.service_points.apartment' },
  { value: ServicePointType.HOUSE, text: 'messages.service_points.house' },
];
