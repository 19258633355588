import { createSlice } from '@reduxjs/toolkit';

const notifyFetchSlice = createSlice({
  name: 'notify',
  initialState: {
    notifyFetchLoading: false,
  },
  reducers: {
    notifyFetch(state) {
      return {
        ...state,
        notifyFetchLoading: true,
      };
    },
    endNotifyFetchCall(state) {
      return {
        ...state,
        notifyFetchLoading: false,
      };
    },
  },
});

export const { notifyFetch, endNotifyFetchCall } = notifyFetchSlice.actions;

export default notifyFetchSlice.reducer;
