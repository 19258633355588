import React from 'react';
import PropType from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navbar from '../../Navbar/Navbar';
import LogoutBtn from '../../Button/LogoutBtn/LogoutBtn';
import HeaderBackBtn from '../Navigation/HeaderBackBtn';
import { ROUTE_PATHS } from '../../../Routes/Routes';
import { closeDropdown, setCookie } from '../../../Helpers/HelperMethods';
import { HeaderDesktopStyles } from './HeaderDesktop.styles';
import { GaEvent, GaAction, GaEvents } from '../../../Helpers/GoogleAnalytics';
import { useDispatch } from 'react-redux';
import { setCustomerLanguage } from '../../../Store/CustomerInfo/customerInfoSlice';

function HeaderDesktop(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { login, logout, isAuthenticated, customerInfo } = props;

  const getDropdownLangs = value => {
    return t(`header.langs.${value}`);
  };

  const handleLangChange = newLang => {
    GaEvent(GaEvents.navigation, { group: GaAction.Language, action: `Select language - ${newLang.toUpperCase()}` });
    i18n.changeLanguage(newLang).then();
    setCookie('lang', newLang);
    document.documentElement.setAttribute('lang', newLang);

    if (isAuthenticated && customerInfo && customerInfo.id) {
      dispatch(setCustomerLanguage({ language: newLang }));
    }
    closeDropdown('langDropdown');
  };

  return (
    <HeaderDesktopStyles>
      <e-section background-color='light'>
        <e-header-block className='header-logo-container'>
          <div className='logo-row'>
            <a
              href={t('homepage')}
              className='main-page-link'
              data-testid='goToElvMainPageBtn'
              onClick={() => {
                GaEvent(GaEvents.navigation, { group: GaAction.Header, action: GaAction.Logo });
              }}
            >
              <div className='flex items-center pointer'>
                <e-icon icon='elv' size='sm' color='primary' />
                <span className=''>{t('header.home')}</span>
              </div>
            </a>
            <div>
              <e-dropdown
                slot='right'
                text={t(`header.langs.${i18n.language}`)}
                type='text'
                align='right'
                caret-color='primary'
                id='langDropdown'
              >
                {i18n.languages &&
                  i18n.languages.map((lang, index) => {
                    if (i18n.language !== lang) {
                      return (
                        <div key={index} onClick={() => handleLangChange(lang)} data-testid='languageBtn'>{`${getDropdownLangs(
                          lang
                        )} »`}</div>
                      );
                    }
                    return null;
                  })}
              </e-dropdown>
            </div>
          </div>
        </e-header-block>
      </e-section>

      <e-section background-color='gradient-accent'>
        <e-section-block class='header-text-container'>
          <div className='text-row'>
            <HeaderBackBtn
              originalBackBtn={
                <Link to={ROUTE_PATHS.INDEX} className='main-page-link'>
                  <h1 className='text-xxl no-margin-bottom pointer'>{t('title')}</h1>
                </Link>
              }
            />

            {!isAuthenticated && (
              <e-button onClick={login} data-testid='loginBtn' color='gradient-primary'>
                <e-icon icon='person' />
                <span>{t('header.login')}</span>
              </e-button>
            )}
            {isAuthenticated && <LogoutBtn logout={logout} />}
          </div>
        </e-section-block>
      </e-section>

      <Navbar hasLabel />

      <hr />
    </HeaderDesktopStyles>
  );
}

HeaderDesktop.propTypes = {
  login: PropType.func,
  logout: PropType.func,
  isAuthenticated: PropType.bool,
};

export default HeaderDesktop;
