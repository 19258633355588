import { put, select } from 'redux-saga/effects';
import moment from 'moment';

import { getLogger } from '../Logger/loggerSlice';
import { NotifySteps } from '../../Helpers/Enums';
import { handleError } from '../../Service/HttpResponseHandler';
import { authenticated } from '../../keycloak';
import { getLocationData } from './dangerNotifySlice';
import { endOutageUpdatePowerIsBack } from './outageUpdatePowerIsBackSlice';
import { endNotifyFetchCall } from './notifyFetchSlice';
import { setApiErrorMessage } from '../Common/commonSlice';
import { getUploadedImagesIds } from '../ImageUpload/imageUploadSlice';
import { getRelatedCustomerId } from '../CustomerInfo/customerInfoSlice';
import { getQuestionnaireResult } from './questionnaireSlice';
import { API_ENDPOINTS, postData } from '../../Service/FetchService';
import { getActiveServicePointEic } from '../ServicePoints/servicePointsSlice';
import { GaAction, GaEvent, GaEvents } from '../../Helpers/GoogleAnalytics';
import { getAddress, getHasServiceFeeAgreement } from './outageNotifySlice';
import { setActiveStep, getPhoneNumber, getPersonName, getDescriptionField, getEmail, getDangerType } from './commonNotifySlice';

export function* sendNotifyOutage(action) {
  const { payload } = action;
  yield put(setActiveStep(NotifySteps.LOADING_STEP));
  const relatedCustomerId = yield select(getRelatedCustomerId);
  const address = yield select(getAddress);
  const questionnaireResult = yield select(getQuestionnaireResult);
  const descriptionField = yield select(getDescriptionField);
  const description = !!descriptionField.descriptionField ? descriptionField.descriptionField : questionnaireResult.description;
  const dangerType = yield select(getDangerType);
  const phoneNumber = yield select(getPhoneNumber);
  const personName = yield select(getPersonName);
  const email = yield select(getEmail);
  const locationData = yield select(getLocationData);
  const hasServiceFeeAgreement = yield select(getHasServiceFeeAgreement);
  const imageIds = yield select(getUploadedImagesIds);
  const body = {
    description,
    questionAnswers: mapQuestionsAnswers(questionnaireResult),
    relatedCustomerId,
    hasServiceFeeAgreement,
    imageIds,
    ...dangerType,
    ...phoneNumber,
    ...personName,
    ...email,
    ...locationData,
    ...getAddressForPayload(address, locationData),
    ...payload,
  };
  try {
    yield put(getLogger({ logger: 'sendNotifyOutage', data: { body: body, address: address, locationData: locationData } }));
  } catch (error) {
    console.log(error);
  }
  const endpoint = authenticated() ? API_ENDPOINTS.NOTIFY_OUTAGE : API_ENDPOINTS.NOTIFY_OUTAGE_PUBLIC;
  try {
    const response = yield postData(endpoint, body);
    if (response.status === 200) {
      yield put(setActiveStep(NotifySteps.SUCCESS_STEP));
      GaEvent(GaEvents.pageNotify, { group: GaAction.OutageDanger, action: GaAction.Success });
    } else {
      yield put(setApiErrorMessage(handleError(response)));
      yield put(setActiveStep(NotifySteps.FAILED_STEP));
      GaEvent(GaEvents.pageNotify, { group: GaAction.OutageDanger, action: GaAction.Error, error_result: handleError(response) });
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
    yield put(setActiveStep(NotifySteps.FAILED_STEP));
    GaEvent(GaEvents.pageNotify, { group: GaAction.OutageDanger, action: GaAction.Error, error_result: handleError(error) });
  }
  yield put(endNotifyFetchCall());
}

export function* updateOutageUpdatePowerIsBack(action) {
  const { payload } = action;
  const { eventId } = payload;

  const eic = yield select(getActiveServicePointEic);
  const relatedCustomerId = yield select(getRelatedCustomerId);

  const body = {
    eventId,
    eic,
    relatedCustomerId,
    startTime: moment(moment.now()),
  };

  try {
    const response = yield postData(`${API_ENDPOINTS.NOTIFY_OUTAGE_UPDATE_POWER_IS_BACK}`, body);
    if (response.status !== 200) {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endOutageUpdatePowerIsBack());
}

const mapQuestionsAnswers = questionsAnswers => {
  const result = Object.keys(questionsAnswers).reduce((array, key) => {
    if (key !== 'description') {
      return [...array, { question: key, answer: questionsAnswers[key] }];
    } else {
      return [...array];
    }
  }, []);

  return { answers: result };
};

const getAddressForPayload = (addressSearch, addressFromMap) => {
  return addressSearch.address ? { ...addressSearch } : { ...addressFromMap };
};
