import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { any, arrayOf, bool, shape, string } from 'prop-types';

import { ROUTE_PATHS } from './Routes';

function RouterProvider(props) {
  const { routes } = props;

  return (
    <Routes>
      {routes.map((route, index) => {
        const View = route.component;
        if (route.path === ROUTE_PATHS.INDEX) {
          return <Route key={index} path={ROUTE_PATHS.INDEX} element={<Navigate replace to={ROUTE_PATHS.MESSAGES} />} />;
        } else if (route.path === ROUTE_PATHS.MAINTENANCE) {
          return <Route key={index} path={ROUTE_PATHS.MAINTENANCE} element={<Navigate replace to={ROUTE_PATHS.MESSAGES} />} />;
        } else {
          return <Route key={index} exact={route.exact} path={route.path} element={<View />} />;
        }
      })}
    </Routes>
  );
}

RouterProvider.propTypes = {
  routes: arrayOf(
    shape({
      component: any.isRequired,
      exact: bool,
      path: string.isRequired,
    })
  ).isRequired,
};

export default RouterProvider;
