export const ResponsiveWidths = {
  mobile: '(max-width: 35em)',
};

export const mediaCss = {
  mobile: '@media (max-width: 35.1em)',
};

export const ErrorTexts = {
  main: 'error.main',
  bad: 'error.bad',
  unauthorized: 'error.unauthorized',
  technical: 'error.technical',
  unavailable: 'error.unavailable',
};

export const ServicePointType = {
  APARTMENT: 'APARTMENT',
  HOUSE: 'HOUSE',
};

export const EventMessageEstimate = {
  0: 0,
  1: 24,
  2: 2,
  3: 8,
  4: 12,
  5: 16,
  6: 20,
  7: 4,
  8: 1,
  9: 6,
  10: 0.5,
  11: 1.5,
  12: 3,
  13: 5,
  14: 7,
  15: 36,
  16: 48,
  17: 72,
};

export const ServicePointEventType = {
  NULL: 0,
  OUTAGE_START: 1,
  OUTAGE_INFO: 2,
  OUTAGE_END: 3,
  TROUBLE_CALL_OUTAGE: 4,
  TROUBLE_CALL_DANGER: 5,
  TROUBLE_CALL_OUTAGE_UPDATE: 6,
  PLANNED_OUTAGE_INFO: 7,
};

export const ServicePointEventTypeReverse = {
  0: 'NULL',
  1: 'OUTAGE_START',
  2: 'OUTAGE_INFO',
  3: 'OUTAGE_END',
  4: 'TROUBLE_CALL_OUTAGE',
  5: 'TROUBLE_CALL_DANGER',
  6: 'TROUBLE_CALL_OUTAGE_UPDATE',
  7: 'PLANNED_OUTAGE_INFO',
};

export const ServicePointStatusEnum = {
  UNDEFINED: 0,
  OUTAGE_START: 1,
  OUTAGE_INFO: 2,
  OUTAGE_END: 3,
  USER_OUTAGE_NOTIFY: 4,
  PLANNED_OUTAGE: 5,
  PLANNED_OUTAGE_ONGOING: 6,
  PLANNED_OUTAGE_DELETED: 7,
  PLANNED_OUTAGE_EXCEPTIONALLY_ADDED: 8,
  PLANNED_OUTAGE_INFO: 9,
};

export const TroubleStat = {
  USER_NOTIFY: -1,
  DMS_DEFAULT: 0,
  PLANNED: 1,
  RUNNING: 2,
  OVER: 3,
  CANCELLED: 4,
};

export const EventDiscriminator = {
  PLANNED: 'PLANNED',
  INTERRUPTION: 'INTERRUPTION',
};

export const PlannedEventEicType = {
  NONE: 0,
  ADDED_EXTRAORDINARY: 1,
  REMOVED_EXTRAORDINARY: 2,
  CHANGED: 3,
};

export const NotifySteps = {
  SP_SELECTION_STEP: 'SP_SELECTION_STEP',
  ADDRESS_CONFIRMATION_STEP: 'ADDRESS_CONFIRMATION_STEP',
  OUTAGE_INFO_STEP: 'OUTAGE_INFO_STEP',
  DANGER_INFO_STEP: 'DANGER_INFO_STEP',
  DANGER_RISK_INFO_STEP: 'DANGER_RISK_INFO_STEP',
  DANGER_CONFIRMATION_STEP: 'DANGER_CONFIRMATION_STEP',
  DANGER_RISK_MAP_STEP: 'DANGER_RISK_MAP_STEP',
  DANGER_MAP_STEP: 'DANGER_MAP_STEP',
  SUCCESS_STEP: 'SUCCESS_STEP',
  FAILED_STEP: 'FAILED_STEP',
  LOADING_STEP: 'LOADING_STEP',
};

export const NotifyType = {
  OUTAGE: 'OUTAGE',
  DANGER: 'DANGER',
  DANGER_RISK: 'DANGER_RISK',
};

export const ValidationFields = {
  address: 'address',
  phone: 'phone',
  personName: 'personName',
  description: 'description',
  isClosedArea: 'isClosedArea',
  location: 'location',
  spType: 'spType',
  photos: 'photos',
  email: 'email',
  feedback: 'feedback',
};

export const NotificationSend = {
  subscribe: 'register',
  unsubscribe: 'unregister',
};

export const NotificationUrlParams = {
  role: 'role',
  eic: 'eic',
};

export const NoticeTypes = {
  warning: 'warning',
  feedback: 'feedback',
  error: 'error',
  success: 'success',
  danger: 'danger',
  feedbackError: 'feedbackError',
};

export const DangerTypes = [
  { situation: 'tree-fallen', description: 'tree_fallen' },
  { situation: 'cable-on-ground', description: 'cable_on_ground' },
  { situation: 'broken-post', description: 'broken_post' },
  { situation: 'open-cabinet', description: 'open_cabinet' },
];
