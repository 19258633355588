import styled from 'styled-components';

import { Colors } from '../AppMain/AppMain.styles';
import { mediaCss } from '../../Helpers/Enums';

export const NavbarStyles = styled.div`
  .navbar {
    list-style: none;
    display: flex;
    justify-content: center;
    background-color: ${Colors.background.white};
  }

  .icon {
    -ms-flex-pack: center;
    justify-content: center;
    fill: currentColor;
    color: inherit;
    height: 1em;
    width: 1em;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    pointer-events: none;
    max-width: none;
  }

  .button-badge {
    padding: 1rem 0;
    position: relative;
  }

  .badge {
    position: absolute;
    top: -3px;
    right: -33px;
    font-size: 0.6em;
    color: ${Colors.background.white};
    border-radius: 50%;
    background: ${Colors.red.primary};
    width: 18px;
    height: 18px;
    text-align: center;
    font-weight: bold;
    line-height: 18px;
  }

  .nav--item {
    color: unset;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    border-bottom: 3px solid transparent;
    cursor: pointer;
    padding: 1em 2%;
    .icon {
      font-size: 2rem;
    }
    ${mediaCss.mobile} {
      width: 100%;
      padding: 0.5em;
      display: flex;
      justify-content: center;
      .icon {
        font-size: 2.5rem;
      }
    }
  }

  .nav--item:hover {
    text-decoration: none;
    border-bottom: 3px solid ${Colors.blue.primary};
  }

  .active {
    border-bottom: 3px solid ${Colors.blue.primary};
    .icon {
      color: ${Colors.blue.primary};
      fill: ${Colors.blue.primary};
    }
  }

  .nav--item--content {
    display: flex;
    align-items: center;
  }
`;
