import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VersionUpdaterModal from '../Modal/VersionUpdaterModal/VersionUpdaterModal';
import { getClientVersion } from '../../Store/AppVersion/appVersionSlice';

function VersionUpdater() {
  const dispatch = useDispatch();

  const { clientVersion } = useSelector(state => state.appVersion);

  const [versionInterval, setVersionInterval] = useState(null);
  const [updateRequired, setUpdateRequired] = useState(false);
  const appUpdaterIntervalTime = parseInt(process.env.REACT_APP_APP_VERSION_CHECK_INTERVAL || window._env_.APP_VERSION_CHECK_INTERVAL);

  useEffect(() => {
    console.debug('Starting app version interval:', appUpdaterIntervalTime);
    setVersionInterval(setInterval(compareVersions, appUpdaterIntervalTime));
  }, []);

  useEffect(() => {
    if (updateRequired) {
      console.debug('Version upgrade required! Clearing interval.');
      clearInterval(versionInterval);
    }
  }, [updateRequired]);

  useEffect(() => {
    try {
      const userMajorVersion = parseInt(process.env.REACT_APP_VERSION.split('.')[0]);
      const serverMajorVersion = parseInt(`${clientVersion}`.split('.')[0]);
      setUpdateRequired(!isNaN(userMajorVersion) && !isNaN(serverMajorVersion) && userMajorVersion !== serverMajorVersion);

      console.debug('active major version:', userMajorVersion, 'available major version:', serverMajorVersion);
    } catch (e) {
      console.debug('Unable to check client app version.', e);
    }
  }, [clientVersion]);

  const compareVersions = () => {
    console.debug('Comparing client version against latest from server.');
    dispatch(getClientVersion());
  };

  return <React.Fragment>{updateRequired && <VersionUpdaterModal open={updateRequired} />}</React.Fragment>;
}

export default VersionUpdater;
