import { put } from 'redux-saga/effects';

import { handleError } from '../../Service/HttpResponseHandler';
import { setApiErrorMessage } from '../Common/commonSlice';
import { API_ENDPOINTS, getData, postData } from '../../Service/FetchService';
import { endCustomerInfoCall, getCustomerInfoSuccess, setCustomerLanguageSuccessStatus, updateCustomerLanguage } from './customerInfoSlice';

export function* readCustomerInfo(payload) {
  const { customerId } = payload.payload;
  if (!customerId) {
    yield put(endCustomerInfoCall());
    return;
  }
  try {
    const response = yield getData(`${API_ENDPOINTS.CUSTOMER_INFO}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(getCustomerInfoSuccess(data));
    } else {
      yield put(setApiErrorMessage(handleError(response)));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
  }
  yield put(endCustomerInfoCall());
}

export function* changeLanguage(payload) {
  const { language } = payload.payload;
  const response = yield postData(`${API_ENDPOINTS.LANGUAGE_CHANGE}`, `"${language}"`);
  try {
    if (response.status === 200) {
      yield put(setCustomerLanguageSuccessStatus(true));
      yield put(updateCustomerLanguage(language));
    }
    if (response.status !== 200) {
      yield put(setApiErrorMessage(handleError(response)));
      yield put(setCustomerLanguageSuccessStatus(false));
    }
  } catch (error) {
    yield put(setApiErrorMessage(handleError(error)));
    yield put(setCustomerLanguageSuccessStatus(false));
  }
}
